<template>
  <div
    ref="container"
    :class="{
      '': indent === 'none',
      'px-container sm:px-container-desktop': indent === 'default',
      'px-container-wide sm:px-container-desktop-wide': indent === 'wide',
      'mx-auto w-full max-w-[68rem] px-container sm:px-container-desktop':
        indent === 'center',
    }"
  >
    <slot />
  </div>
</template>

<script setup>
defineProps({
  indent: {
    type: String,
    default: 'default',
  },
})

const container = ref(null)

defineExpose({
  container,
})
</script>
