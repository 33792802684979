<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 302.71 88"
    aria-hidden="true"
  >
    <defs>
      <linearGradient
        id="b"
        x1=".12"
        x2=".88"
        y1=".94"
        y2=".12"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#0091d2" />
        <stop offset="1" stop-color="#37a7df" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h302.71v88H0z" />
      </clipPath>
    </defs>
    <g clip-path="url(#a)">
      <path
        fill="url(#b)"
        d="m11.52 87.4 33.7-.03a8.88 8.88 0 0 0 8.88-8.89l-.11-24.07a8.87 8.87 0 0 0-8.87-8.84h-5.98l-1.68-.02a3.18 3.18 0 1 0 0 6.36l.88.01h6.75a2.52 2.52 0 0 1 2.53 2.51l.12 24.08a2.52 2.52 0 0 1-2.52 2.5V81c-.34.03-.83.03-1.51.03l-33.72.04h-.01a2.52 2.52 0 0 1-2.52-2.52L7.4 54.47a2.52 2.52 0 0 1 2.52-2.52v.03c.33-.03.8-.03 1.43-.03l7.77-.01a8.9 8.9 0 0 0 8.87-8.9h.07c-.07-.6-.07-1.66-.07-3.5l-.02-19.36L52.31 5.9a3.15 3.15 0 0 0 1.5-2.7A3.2 3.2 0 0 0 49.07.41l-.42.23L1.5 28.26A3.17 3.17 0 0 0 0 30.96a3.2 3.2 0 0 0 5.03 2.62l16.55-9.69.04 19.16a2.53 2.53 0 0 1-2.51 2.53v-.03c-.34.03-.8.03-1.44.03l-7.77.01a8.88 8.88 0 0 0-8.87 8.9H.97c.07.64.07 1.76.07 3.76l.05 20.32a8.88 8.88 0 0 0 8.89 8.84H10v.03c.35-.03.83-.03 1.51-.03"
      />
      <g fill="currentColor">
        <path
          d="M81.7 27.8a3.11 3.11 0 0 1-.3 1.37 2.99 2.99 0 0 1-.83 1.04 3.73 3.73 0 0 1-1.28.64 5.64 5.64 0 0 1-1.61.22 6.7 6.7 0 0 1-2.22-.33 4.54 4.54 0 0 1-1.74-1.15l1.13-1.1a3.3 3.3 0 0 0 1.3.83 4.96 4.96 0 0 0 1.56.23 2.82 2.82 0 0 0 1.7-.45 1.68 1.68 0 0 0 .16-2.42 1.46 1.46 0 0 0-.47-.3 4.23 4.23 0 0 0-.75-.17l-1.32-.19a3.78 3.78 0 0 1-2.12-.9 2.79 2.79 0 0 1-.82-2.13 3.32 3.32 0 0 1 .26-1.32 3 3 0 0 1 .74-1.04 3.26 3.26 0 0 1 1.17-.68 4.88 4.88 0 0 1 1.57-.23 5.65 5.65 0 0 1 1.93.3 4.54 4.54 0 0 1 1.54.97l-1.08 1.06a2.73 2.73 0 0 0-1.12-.68 4.56 4.56 0 0 0-1.31-.18 2.13 2.13 0 0 0-1.52.5 1.64 1.64 0 0 0-.52 1.24 1.54 1.54 0 0 0 .1.57 1.24 1.24 0 0 0 .32.47 2.38 2.38 0 0 0 1.25.51l1.28.2a5.7 5.7 0 0 1 1.25.31 3.08 3.08 0 0 1 .84.51 2.56 2.56 0 0 1 .68 1 3.54 3.54 0 0 1 .23 1.3"
        />
        <path
          d="M85.12 27.46a2.4 2.4 0 0 0 .52 1.63 1.89 1.89 0 0 0 1.5.6 2.57 2.57 0 0 0 1.1-.2 3.08 3.08 0 0 0 .84-.62l1.02.96a5.77 5.77 0 0 1-.62.54 3.47 3.47 0 0 1-.66.38 3.54 3.54 0 0 1-.77.24 5.11 5.11 0 0 1-.93.08 4.53 4.53 0 0 1-1.4-.21 2.78 2.78 0 0 1-1.14-.7 3.37 3.37 0 0 1-.76-1.26 5.7 5.7 0 0 1-.28-1.91 4.44 4.44 0 0 1 .91-3 3.1 3.1 0 0 1 2.46-1.06 3.46 3.46 0 0 1 1.43.28 3.03 3.03 0 0 1 1.06.79 3.47 3.47 0 0 1 .66 1.2 5.05 5.05 0 0 1 .23 1.56v.7Zm3.59-1.11a5.16 5.16 0 0 0-.06-.59 2.02 2.02 0 0 0-.17-.5 1.59 1.59 0 0 0-.6-.72 1.7 1.7 0 0 0-.97-.28 1.75 1.75 0 0 0-.98.28 1.58 1.58 0 0 0-.6.73 1.67 1.67 0 0 0-.16.5 6.3 6.3 0 0 0-.04.58Z"
        />
        <path d="M92.52 19.76h1.7v1.7h-1.7Zm.05 3.27h1.6v7.94h-1.6Z" />
        <path
          d="M99.01 30.97a2.43 2.43 0 0 1-.98-.18 1.93 1.93 0 0 1-.68-.47 1.86 1.86 0 0 1-.4-.7 2.76 2.76 0 0 1-.12-.84V19.8h1.6v8.88a1.08 1.08 0 0 0 .18.68.82.82 0 0 0 .68.24h.7v1.36Z"
        />
        <path
          d="M108.43 27c0 .3 0 .6-.02.92a5.74 5.74 0 0 1-.12.91 3.95 3.95 0 0 1-.27.84 2.35 2.35 0 0 1-.48.7 2.27 2.27 0 0 1-.84.51 3.18 3.18 0 0 1-1.1.19 3.13 3.13 0 0 1-1.16-.2 2.43 2.43 0 0 1-.95-.75v.85h-1.57V19.81h1.6v4.02a2.33 2.33 0 0 1 .94-.7 3.23 3.23 0 0 1 1.15-.2 3.1 3.1 0 0 1 1.1.2 2.27 2.27 0 0 1 .83.51 2.36 2.36 0 0 1 .48.7 4.02 4.02 0 0 1 .27.82 5.6 5.6 0 0 1 .12.91q.03.47.02.93m-1.6 0a9.12 9.12 0 0 0-.05-1.02 2.63 2.63 0 0 0-.23-.83 1.37 1.37 0 0 0-.5-.58 1.9 1.9 0 0 0-1.74 0 1.44 1.44 0 0 0-.5.58 2.5 2.5 0 0 0-.23.83 9.14 9.14 0 0 0 0 2.03 2.57 2.57 0 0 0 .23.84 1.43 1.43 0 0 0 .5.58 1.89 1.89 0 0 0 1.74 0 1.36 1.36 0 0 0 .5-.58 2.72 2.72 0 0 0 .23-.84 9.1 9.1 0 0 0 .05-1.02"
        />
        <path
          d="M115.05 30.97v-.72a2.75 2.75 0 0 1-.91.63 3.3 3.3 0 0 1-1.21.19 3.82 3.82 0 0 1-1.27-.18 2.29 2.29 0 0 1-.87-.54 2.37 2.37 0 0 1-.62-1.71 2.16 2.16 0 0 1 .68-1.65 2.92 2.92 0 0 1 2.07-.64h2.1v-.57a1.45 1.45 0 0 0-.4-1.12 1.97 1.97 0 0 0-1.35-.36 2.54 2.54 0 0 0-1.03.17 1.96 1.96 0 0 0-.71.6l-1.05-.99a3.02 3.02 0 0 1 1.21-.9 4.74 4.74 0 0 1 1.65-.24q3.28 0 3.28 2.72v5.32Zm-.03-3.5h-1.87a1.65 1.65 0 0 0-1.07.3 1.03 1.03 0 0 0-.36.84 1.02 1.02 0 0 0 .35.84 1.73 1.73 0 0 0 1.11.29 4.22 4.22 0 0 0 .82-.07 1.31 1.31 0 0 0 .67-.39 1.63 1.63 0 0 0 .35-1.17Z"
        />
        <path
          d="M124.06 30.97V26.1a1.7 1.7 0 0 0-.46-1.32 1.77 1.77 0 0 0-2.28 0 1.69 1.69 0 0 0-.48 1.32v4.88h-1.6V19.8h1.6v4.02a2.6 2.6 0 0 1 .94-.68 2.9 2.9 0 0 1 1.12-.22 3.09 3.09 0 0 1 1.18.21 2.4 2.4 0 0 1 .87.6 2.55 2.55 0 0 1 .54.92 3.74 3.74 0 0 1 .18 1.19v5.12Z"
        />
        <path
          d="M133.14 30.97v-4.84a1.75 1.75 0 0 0-.46-1.35 1.63 1.63 0 0 0-1.14-.42 1.59 1.59 0 0 0-1.65 1.77v4.84h-1.6v-7.94h1.57v.8a2.54 2.54 0 0 1 .97-.68 3.15 3.15 0 0 1 1.15-.22 3.1 3.1 0 0 1 1.11.19 2.54 2.54 0 0 1 .86.53 2.45 2.45 0 0 1 .62 1.01 4.16 4.16 0 0 1 .18 1.23v5.08Z"
        />
        <path
          d="M138.55 27.46a2.4 2.4 0 0 0 .52 1.63 1.89 1.89 0 0 0 1.5.6 2.57 2.57 0 0 0 1.1-.2 3.09 3.09 0 0 0 .84-.62l1.02.96a5.77 5.77 0 0 1-.62.54 3.45 3.45 0 0 1-.66.38 3.52 3.52 0 0 1-.77.24 5.1 5.1 0 0 1-.94.08 4.52 4.52 0 0 1-1.4-.21 2.78 2.78 0 0 1-1.13-.7 3.35 3.35 0 0 1-.77-1.26 5.68 5.68 0 0 1-.28-1.91 4.44 4.44 0 0 1 .92-3 3.1 3.1 0 0 1 2.46-1.06 3.47 3.47 0 0 1 1.43.28 3.02 3.02 0 0 1 1.06.79 3.47 3.47 0 0 1 .66 1.2 5.03 5.03 0 0 1 .23 1.56v.7Zm3.58-1.11a5.01 5.01 0 0 0-.06-.59 2.02 2.02 0 0 0-.16-.5 1.6 1.6 0 0 0-.6-.72 1.7 1.7 0 0 0-.97-.28 1.75 1.75 0 0 0-.99.28 1.58 1.58 0 0 0-.6.73 1.68 1.68 0 0 0-.15.5 6.3 6.3 0 0 0-.05.58Z"
        />
        <path
          d="M150.85 30.97v-4.84a1.75 1.75 0 0 0-.47-1.35 1.63 1.63 0 0 0-1.14-.42 1.59 1.59 0 0 0-1.64 1.77v4.84H146v-7.94h1.57v.8a2.54 2.54 0 0 1 .96-.68 3.15 3.15 0 0 1 1.16-.22 3.1 3.1 0 0 1 1.1.19 2.53 2.53 0 0 1 .86.53 2.46 2.46 0 0 1 .63 1.01 4.16 4.16 0 0 1 .17 1.23v5.08Z"
        />
        <path
          d="M166.18 27.8a3.11 3.11 0 0 1-.3 1.37 2.99 2.99 0 0 1-.84 1.04 3.74 3.74 0 0 1-1.27.64 5.64 5.64 0 0 1-1.62.22 6.7 6.7 0 0 1-2.21-.33 4.54 4.54 0 0 1-1.74-1.15l1.13-1.11a3.3 3.3 0 0 0 1.3.84 4.96 4.96 0 0 0 1.55.23 2.82 2.82 0 0 0 1.7-.45 1.68 1.68 0 0 0 .17-2.42 1.46 1.46 0 0 0-.47-.3 4.23 4.23 0 0 0-.76-.17l-1.32-.2a3.78 3.78 0 0 1-2.12-.89 2.79 2.79 0 0 1-.81-2.13 3.32 3.32 0 0 1 .26-1.32 3 3 0 0 1 .73-1.05 3.26 3.26 0 0 1 1.17-.67 4.88 4.88 0 0 1 1.57-.23 5.65 5.65 0 0 1 1.94.3 4.54 4.54 0 0 1 1.53.97l-1.08 1.07a2.73 2.73 0 0 0-1.12-.69 4.56 4.56 0 0 0-1.31-.18 2.13 2.13 0 0 0-1.52.5 1.64 1.64 0 0 0-.53 1.24 1.53 1.53 0 0 0 .1.58 1.24 1.24 0 0 0 .33.46 2.38 2.38 0 0 0 1.25.51l1.27.2a5.7 5.7 0 0 1 1.26.31 3.08 3.08 0 0 1 .83.51 2.56 2.56 0 0 1 .69 1 3.54 3.54 0 0 1 .23 1.3"
        />
        <path
          d="M174.2 29.94a3.66 3.66 0 0 1-1.18.86 3.6 3.6 0 0 1-1.45.27 4 4 0 0 1-1.31-.22 2.97 2.97 0 0 1-1.14-.71 3.58 3.58 0 0 1-.8-1.26 5.99 5.99 0 0 1 0-3.78 3.51 3.51 0 0 1 .8-1.26 3.04 3.04 0 0 1 1.14-.7 4.02 4.02 0 0 1 1.3-.21 3.7 3.7 0 0 1 1.45.25 3.56 3.56 0 0 1 1.18.86l-1.1 1.06a2.68 2.68 0 0 0-.71-.58 1.77 1.77 0 0 0-.81-.17 1.75 1.75 0 0 0-1.48.7 1.87 1.87 0 0 0-.37.78 5.35 5.35 0 0 0-.1 1.16 5.46 5.46 0 0 0 .1 1.16 1.88 1.88 0 0 0 .37.78 1.75 1.75 0 0 0 1.48.7 1.77 1.77 0 0 0 .8-.17 2.67 2.67 0 0 0 .72-.58Z"
        />
        <path
          d="M181.02 30.97V26.1a1.7 1.7 0 0 0-.46-1.32 1.77 1.77 0 0 0-2.29 0 1.68 1.68 0 0 0-.47 1.32v4.88h-1.6V19.8h1.6v4.02a2.6 2.6 0 0 1 .94-.68 2.9 2.9 0 0 1 1.12-.22 3.1 3.1 0 0 1 1.18.21 2.4 2.4 0 0 1 .87.6 2.55 2.55 0 0 1 .53.92 3.74 3.74 0 0 1 .18 1.19v5.12Z"
        />
        <path
          d="M193.04 30.97h-1.37l-1.87-5.55-1.85 5.55h-1.38l-2.47-7.94h1.7l1.54 5.6 1.85-5.6h1.25l1.83 5.6 1.54-5.6h1.71Z"
        />
        <path
          d="M198.13 27.46a2.4 2.4 0 0 0 .52 1.63 1.89 1.89 0 0 0 1.5.6 2.57 2.57 0 0 0 1.1-.2 3.09 3.09 0 0 0 .84-.62l1.02.96a5.77 5.77 0 0 1-.62.54 3.44 3.44 0 0 1-.66.38 3.51 3.51 0 0 1-.77.24 5.1 5.1 0 0 1-.94.08 4.52 4.52 0 0 1-1.4-.21 2.78 2.78 0 0 1-1.13-.7 3.35 3.35 0 0 1-.76-1.26 5.67 5.67 0 0 1-.29-1.91 4.44 4.44 0 0 1 .92-3 3.1 3.1 0 0 1 2.46-1.06 3.46 3.46 0 0 1 1.43.28 3.03 3.03 0 0 1 1.06.79 3.47 3.47 0 0 1 .66 1.2 5.03 5.03 0 0 1 .23 1.56v.7Zm3.58-1.11a5.01 5.01 0 0 0-.05-.59 2.01 2.01 0 0 0-.17-.5 1.59 1.59 0 0 0-.6-.72 1.7 1.7 0 0 0-.97-.28 1.75 1.75 0 0 0-.98.28 1.58 1.58 0 0 0-.6.73 1.68 1.68 0 0 0-.16.5 6.3 6.3 0 0 0-.05.58Z"
        />
        <path d="M205.53 19.76h1.7v1.7h-1.7Zm.04 3.27h1.6v7.94h-1.6Z" />
        <path
          d="M209.28 30.97V29.7l3.96-5.25h-3.74v-1.42h5.72v1.27l-4 5.25h4v1.42Z"
        />
        <path
          d="m80.88 49.95-2.35-4.7H76.4v4.7h-1.7V38.8h4.34a4 4 0 0 1 1.43.24 3.27 3.27 0 0 1 1.1.68 2.88 2.88 0 0 1 .7 1.03 3.46 3.46 0 0 1 .24 1.31 2.87 2.87 0 0 1-.64 1.95 3.2 3.2 0 0 1-1.6 1.01l2.59 4.94Zm-.06-7.88a1.63 1.63 0 0 0-.53-1.3 2 2 0 0 0-1.37-.46h-2.51v3.5h2.5a2.03 2.03 0 0 0 1.38-.45 1.6 1.6 0 0 0 .53-1.28"
        />
        <path
          d="M86.15 46.44a2.4 2.4 0 0 0 .51 1.63 1.89 1.89 0 0 0 1.51.6 2.57 2.57 0 0 0 1.1-.2 3.09 3.09 0 0 0 .83-.62l1.02.96a5.78 5.78 0 0 1-.62.54 3.45 3.45 0 0 1-.66.38 3.52 3.52 0 0 1-.77.23 5.1 5.1 0 0 1-.93.08 4.52 4.52 0 0 1-1.4-.2 2.79 2.79 0 0 1-1.14-.7 3.35 3.35 0 0 1-.76-1.26 5.67 5.67 0 0 1-.28-1.91 4.44 4.44 0 0 1 .92-3 3.1 3.1 0 0 1 2.45-1.06 3.47 3.47 0 0 1 1.43.28 3.03 3.03 0 0 1 1.06.78 3.48 3.48 0 0 1 .66 1.21 5.05 5.05 0 0 1 .23 1.55v.7Zm3.58-1.11a4.98 4.98 0 0 0-.06-.6 2.02 2.02 0 0 0-.16-.49 1.59 1.59 0 0 0-.6-.73 1.7 1.7 0 0 0-.98-.27 1.75 1.75 0 0 0-.98.27 1.58 1.58 0 0 0-.6.73 1.68 1.68 0 0 0-.15.5 6.3 6.3 0 0 0-.05.58Z"
        />
        <path
          d="M103.3 49.95v-4.84a1.75 1.75 0 0 0-.47-1.35 1.63 1.63 0 0 0-1.13-.42 1.94 1.94 0 0 0-.63.1 1.46 1.46 0 0 0-.53.31 1.49 1.49 0 0 0-.36.53 1.9 1.9 0 0 0-.13.75v4.92h-1.6v-4.84a1.75 1.75 0 0 0-.47-1.35 1.63 1.63 0 0 0-1.14-.42 1.59 1.59 0 0 0-1.65 1.77v4.84h-1.6V42h1.58v.8a2.7 2.7 0 0 1 .96-.66 3.04 3.04 0 0 1 1.16-.23 2.52 2.52 0 0 1 2.26 1.16 3.08 3.08 0 0 1 2.5-1.16 3.3 3.3 0 0 1 1.13.19 2.5 2.5 0 0 1 .88.53 2.68 2.68 0 0 1 .65 1.01 3.7 3.7 0 0 1 .2 1.23v5.08Z"
        />
        <path
          d="M113.8 45.97a7.45 7.45 0 0 1-.2 1.78 2.99 2.99 0 0 1-.75 1.35 3.15 3.15 0 0 1-.98.68 3.4 3.4 0 0 1-1.4.27 3.33 3.33 0 0 1-1.4-.27 3.15 3.15 0 0 1-.98-.68 2.99 2.99 0 0 1-.76-1.35 8.42 8.42 0 0 1 0-3.54 3 3 0 0 1 .76-1.36 3.17 3.17 0 0 1 .97-.67 3.32 3.32 0 0 1 1.4-.27 3.39 3.39 0 0 1 1.4.27 3.17 3.17 0 0 1 .99.67 3 3 0 0 1 .75 1.36 7.38 7.38 0 0 1 .2 1.76m-1.6 0a7.83 7.83 0 0 0-.1-1.24 1.66 1.66 0 0 0-.46-.94 1.6 1.6 0 0 0-1.17-.45 1.58 1.58 0 0 0-1.17.45 1.67 1.67 0 0 0-.45.94 8.26 8.26 0 0 0 0 2.48 1.67 1.67 0 0 0 .45.94 1.58 1.58 0 0 0 1.17.47 1.6 1.6 0 0 0 1.17-.47 1.67 1.67 0 0 0 .46-.94 7.83 7.83 0 0 0 .1-1.24"
        />
        <path
          d="M120.97 49.95v-4.84a1.75 1.75 0 0 0-.46-1.35 1.63 1.63 0 0 0-1.14-.42 1.59 1.59 0 0 0-1.65 1.77v4.84h-1.6V42h1.57v.8a2.54 2.54 0 0 1 .96-.67 3.15 3.15 0 0 1 1.16-.22 3.1 3.1 0 0 1 1.1.19 2.53 2.53 0 0 1 .86.53 2.45 2.45 0 0 1 .63 1.01 4.16 4.16 0 0 1 .17 1.23v5.08Z"
        />
        <path
          d="M127.57 49.95a2.31 2.31 0 0 1-.98-.19 1.98 1.98 0 0 1-.67-.49 2.04 2.04 0 0 1-.4-.7 2.57 2.57 0 0 1-.14-.83v-4.37h-.9v-1.23h.9v-2.41h1.6v2.41h1.54v1.23h-1.54v4.3a1 1 0 0 0 .22.68.84.84 0 0 0 .66.24h.66v1.37Z"
        />
        <path
          d="M131.9 46.44a2.4 2.4 0 0 0 .52 1.63 1.9 1.9 0 0 0 1.51.6 2.57 2.57 0 0 0 1.1-.2 3.05 3.05 0 0 0 .83-.62l1.02.96a5.67 5.67 0 0 1-.62.54 3.39 3.39 0 0 1-1.43.62 5.03 5.03 0 0 1-.93.08 4.52 4.52 0 0 1-1.4-.22 2.8 2.8 0 0 1-1.14-.69 3.35 3.35 0 0 1-.76-1.26 5.67 5.67 0 0 1-.28-1.91 4.44 4.44 0 0 1 .92-3 3.1 3.1 0 0 1 2.45-1.06 3.46 3.46 0 0 1 1.43.28 3 3 0 0 1 1.06.79 3.44 3.44 0 0 1 .66 1.2 5.02 5.02 0 0 1 .23 1.55v.71Zm3.59-1.11a4.78 4.78 0 0 0-.06-.6 1.98 1.98 0 0 0-.16-.48 1.6 1.6 0 0 0-.6-.73 1.7 1.7 0 0 0-.98-.28 1.75 1.75 0 0 0-.98.27 1.58 1.58 0 0 0-.6.73 1.7 1.7 0 0 0-.15.5q-.04.22-.05.58Zm-1.35-4.77h-1.21l1.17-2.54h1.78Z"
        />
        <path
          d="M140.53 46.44a2.4 2.4 0 0 0 .51 1.63 1.89 1.89 0 0 0 1.51.6 2.58 2.58 0 0 0 1.1-.2 3.1 3.1 0 0 0 .84-.62l1.02.96a5.62 5.62 0 0 1-.62.54 3.44 3.44 0 0 1-.66.38 3.52 3.52 0 0 1-.77.23 5.1 5.1 0 0 1-.94.08 4.52 4.52 0 0 1-1.4-.2 2.79 2.79 0 0 1-1.14-.7 3.35 3.35 0 0 1-.76-1.26 5.68 5.68 0 0 1-.28-1.91 4.44 4.44 0 0 1 .92-3 3.1 3.1 0 0 1 2.46-1.06 3.47 3.47 0 0 1 1.43.28 3.03 3.03 0 0 1 1.06.78 3.48 3.48 0 0 1 .66 1.21 5.05 5.05 0 0 1 .22 1.55v.7Zm3.58-1.11a4.97 4.97 0 0 0-.06-.6 2.01 2.01 0 0 0-.16-.49 1.6 1.6 0 0 0-.6-.73 1.7 1.7 0 0 0-.98-.27 1.75 1.75 0 0 0-.98.27 1.58 1.58 0 0 0-.6.73 1.7 1.7 0 0 0-.16.5 6.3 6.3 0 0 0-.04.58Z"
        />
        <path
          d="M153.86 47.52a2.3 2.3 0 0 1-.25 1.1 2.33 2.33 0 0 1-.69.8 3.03 3.03 0 0 1-1.05.47 5.3 5.3 0 0 1-1.32.16 6.86 6.86 0 0 1-1.8-.23 3.54 3.54 0 0 1-1.55-.92l1.06-1.05a2.38 2.38 0 0 0 1.09.67 4.57 4.57 0 0 0 1.2.16 2.62 2.62 0 0 0 1.24-.26.96.96 0 0 0 .26-1.48 1.3 1.3 0 0 0-.83-.3l-1.27-.1a3 3 0 0 1-1.74-.62 1.96 1.96 0 0 1-.62-1.58 2.17 2.17 0 0 1 .88-1.8 3.07 3.07 0 0 1 .96-.46 4.03 4.03 0 0 1 1.17-.17 6.33 6.33 0 0 1 1.64.2 3.2 3.2 0 0 1 1.3.7l-1 1.01a2.26 2.26 0 0 0-.89-.45 4.23 4.23 0 0 0-1.07-.13 1.75 1.75 0 0 0-1.09.28.9.9 0 0 0-.36.74.85.85 0 0 0 .23.6 1.38 1.38 0 0 0 .87.3l1.26.11a2.97 2.97 0 0 1 1.75.64 2.02 2.02 0 0 1 .62 1.6"
        />
        <path
          d="M168.26 49.95V42.5l-2.58 5.44h-1.26l-2.64-5.44v7.46h-1.7V38.8h1.7l3.27 6.94 3.2-6.94h1.7v11.16Z"
        />
        <path
          d="M174 46.44a2.4 2.4 0 0 0 .52 1.63 1.9 1.9 0 0 0 1.5.6 2.57 2.57 0 0 0 1.1-.2 3.05 3.05 0 0 0 .84-.62l1.02.96a5.68 5.68 0 0 1-.62.54 3.39 3.39 0 0 1-1.43.62 5.03 5.03 0 0 1-.93.08 4.52 4.52 0 0 1-1.4-.22 2.8 2.8 0 0 1-1.14-.69 3.35 3.35 0 0 1-.76-1.26 5.67 5.67 0 0 1-.28-1.91 4.44 4.44 0 0 1 .91-3 3.1 3.1 0 0 1 2.46-1.06 3.46 3.46 0 0 1 1.43.28 3 3 0 0 1 1.06.79 3.44 3.44 0 0 1 .66 1.2 5.02 5.02 0 0 1 .23 1.56v.7Zm3.58-1.11a4.78 4.78 0 0 0-.05-.6 1.98 1.98 0 0 0-.17-.48 1.6 1.6 0 0 0-.6-.73 1.7 1.7 0 0 0-.97-.28 1.75 1.75 0 0 0-.98.28 1.58 1.58 0 0 0-.6.72 1.7 1.7 0 0 0-.16.5q-.03.22-.05.59Zm-1.35-4.77h-1.2l1.17-2.54h1.77Z"
        />
        <path
          d="M187.21 48.92a3.66 3.66 0 0 1-1.18.86 3.6 3.6 0 0 1-1.44.26 4 4 0 0 1-1.31-.21 2.98 2.98 0 0 1-1.13-.71 3.58 3.58 0 0 1-.8-1.27 5.99 5.99 0 0 1 0-3.77 3.51 3.51 0 0 1 .8-1.26 3.04 3.04 0 0 1 1.13-.7 4.02 4.02 0 0 1 1.3-.22 3.7 3.7 0 0 1 1.45.26 3.56 3.56 0 0 1 1.18.85l-1.1 1.07a2.68 2.68 0 0 0-.71-.58 1.77 1.77 0 0 0-.81-.17 1.75 1.75 0 0 0-1.48.7 1.87 1.87 0 0 0-.37.78 5.35 5.35 0 0 0-.1 1.15 5.46 5.46 0 0 0 .1 1.16 1.88 1.88 0 0 0 .37.79 1.93 1.93 0 0 0 2.29.53 2.67 2.67 0 0 0 .71-.58Z"
        />
        <path
          d="M193.45 49.95v-.72a2.75 2.75 0 0 1-.91.63 3.3 3.3 0 0 1-1.21.19 3.83 3.83 0 0 1-1.27-.19 2.29 2.29 0 0 1-.87-.54 2.37 2.37 0 0 1-.63-1.7 2.16 2.16 0 0 1 .69-1.65 2.92 2.92 0 0 1 2.06-.64h2.1v-.57a1.45 1.45 0 0 0-.4-1.12 1.97 1.97 0 0 0-1.34-.37 2.54 2.54 0 0 0-1.03.17 1.96 1.96 0 0 0-.71.6l-1.05-.98a3 3 0 0 1 1.21-.9 4.74 4.74 0 0 1 1.65-.25q3.28 0 3.28 2.73v5.32Zm-.03-3.51h-1.87a1.65 1.65 0 0 0-1.07.3 1.03 1.03 0 0 0-.36.85 1.02 1.02 0 0 0 .35.83 1.73 1.73 0 0 0 1.1.3 4.23 4.23 0 0 0 .82-.08 1.31 1.31 0 0 0 .68-.38 1.63 1.63 0 0 0 .35-1.18Z"
        />
        <path
          d="M202.5 49.95v-4.84a1.74 1.74 0 0 0-.47-1.35 1.63 1.63 0 0 0-1.14-.42 1.59 1.59 0 0 0-1.65 1.77v4.84h-1.6V42h1.58v.8a2.54 2.54 0 0 1 .96-.67 3.15 3.15 0 0 1 1.16-.22 3.1 3.1 0 0 1 1.1.19 2.53 2.53 0 0 1 .86.53 2.45 2.45 0 0 1 .63 1.01 4.16 4.16 0 0 1 .17 1.23v5.08Z"
        />
        <path d="M206.67 38.74h1.7v1.7h-1.7Zm.05 3.26h1.6v7.95h-1.6Z" />
        <path
          d="M215.56 53.16v-4.02a2.4 2.4 0 0 1-.94.71 3.38 3.38 0 0 1-2.25 0 2.28 2.28 0 0 1-.83-.51 2.33 2.33 0 0 1-.48-.69 3.86 3.86 0 0 1-.27-.84 5.84 5.84 0 0 1-.12-.92 19.35 19.35 0 0 1 0-1.85 5.69 5.69 0 0 1 .12-.9 3.93 3.93 0 0 1 .27-.84 2.34 2.34 0 0 1 .48-.68 2.26 2.26 0 0 1 .84-.52 3.42 3.42 0 0 1 2.26 0 2.46 2.46 0 0 1 .95.75V42h1.57v11.16Zm0-7.2a8.93 8.93 0 0 0-.05-1 2.66 2.66 0 0 0-.23-.85 1.38 1.38 0 0 0-.5-.57 1.9 1.9 0 0 0-1.74 0 1.44 1.44 0 0 0-.5.57 2.5 2.5 0 0 0-.23.84 9.26 9.26 0 0 0 0 2.03 2.57 2.57 0 0 0 .23.84 1.43 1.43 0 0 0 .5.58 1.89 1.89 0 0 0 1.74 0 1.36 1.36 0 0 0 .5-.58 2.74 2.74 0 0 0 .23-.84 8.92 8.92 0 0 0 .05-1.02"
        />
        <path
          d="M224.68 49.95v-.8a2.53 2.53 0 0 1-.97.67 3.13 3.13 0 0 1-1.16.22 3.1 3.1 0 0 1-1.1-.18 2.53 2.53 0 0 1-.86-.54 2.43 2.43 0 0 1-.63-1 4.12 4.12 0 0 1-.17-1.24V42h1.6v4.85a1.75 1.75 0 0 0 .46 1.34 1.63 1.63 0 0 0 1.14.43 2.01 2.01 0 0 0 .64-.1 1.31 1.31 0 0 0 .53-.32 1.61 1.61 0 0 0 .35-.56 2.15 2.15 0 0 0 .13-.8V42h1.6v7.95Z"
        />
        <path
          d="M230.13 46.44a2.4 2.4 0 0 0 .51 1.63 1.89 1.89 0 0 0 1.51.6 2.58 2.58 0 0 0 1.1-.2 3.1 3.1 0 0 0 .83-.62l1.02.96a5.76 5.76 0 0 1-.62.54 3.45 3.45 0 0 1-.66.38 3.5 3.5 0 0 1-.77.23 5.1 5.1 0 0 1-.93.08 4.52 4.52 0 0 1-1.4-.2 2.78 2.78 0 0 1-1.14-.7 3.36 3.36 0 0 1-.76-1.26 5.68 5.68 0 0 1-.28-1.91 4.44 4.44 0 0 1 .92-3 3.1 3.1 0 0 1 2.45-1.06 3.47 3.47 0 0 1 1.43.28 3.03 3.03 0 0 1 1.06.78 3.48 3.48 0 0 1 .66 1.21 5.05 5.05 0 0 1 .23 1.55v.7Zm3.58-1.11a5.04 5.04 0 0 0-.05-.6 2.02 2.02 0 0 0-.17-.48 1.6 1.6 0 0 0-.6-.73 1.7 1.7 0 0 0-.97-.28 1.75 1.75 0 0 0-.98.28 1.58 1.58 0 0 0-.6.72 1.7 1.7 0 0 0-.16.5 6.3 6.3 0 0 0-.05.59Z"
        />
        <path
          d="M243.46 47.52a2.3 2.3 0 0 1-.25 1.1 2.33 2.33 0 0 1-.69.8 3.03 3.03 0 0 1-1.05.47 5.3 5.3 0 0 1-1.32.16 6.86 6.86 0 0 1-1.8-.23 3.54 3.54 0 0 1-1.55-.92l1.05-1.05a2.38 2.38 0 0 0 1.1.67 4.56 4.56 0 0 0 1.2.16 2.62 2.62 0 0 0 1.24-.26.96.96 0 0 0 .26-1.48 1.3 1.3 0 0 0-.83-.3l-1.27-.1a3 3 0 0 1-1.74-.62 1.96 1.96 0 0 1-.62-1.58 2.21 2.21 0 0 1 .24-1.03 2.19 2.19 0 0 1 .64-.76 3.07 3.07 0 0 1 .95-.47 4.03 4.03 0 0 1 1.17-.17 6.33 6.33 0 0 1 1.65.2 3.2 3.2 0 0 1 1.3.7l-1 1.01a2.26 2.26 0 0 0-.9-.45 4.23 4.23 0 0 0-1.07-.13 1.75 1.75 0 0 0-1.08.28.9.9 0 0 0-.36.74.85.85 0 0 0 .23.6 1.38 1.38 0 0 0 .87.3l1.25.11a2.97 2.97 0 0 1 1.76.64 2.02 2.02 0 0 1 .62 1.6"
        />
        <path
          d="M256.67 46.77a3.12 3.12 0 0 1-.3 1.38 2.99 2.99 0 0 1-.83 1.03 3.74 3.74 0 0 1-1.27.65 5.64 5.64 0 0 1-1.62.22 6.7 6.7 0 0 1-2.21-.33 4.54 4.54 0 0 1-1.75-1.15l1.13-1.11a3.3 3.3 0 0 0 1.3.84 4.96 4.96 0 0 0 1.56.22 2.82 2.82 0 0 0 1.7-.44 1.68 1.68 0 0 0 .16-2.42 1.45 1.45 0 0 0-.47-.3 4.23 4.23 0 0 0-.75-.18L252 45a3.78 3.78 0 0 1-2.12-.9 2.79 2.79 0 0 1-.82-2.13 3.32 3.32 0 0 1 .26-1.32 3 3 0 0 1 .74-1.05 3.26 3.26 0 0 1 1.17-.67 4.88 4.88 0 0 1 1.57-.23 5.65 5.65 0 0 1 1.93.3 4.54 4.54 0 0 1 1.54.97l-1.08 1.06a2.73 2.73 0 0 0-1.13-.68 4.55 4.55 0 0 0-1.3-.18 2.14 2.14 0 0 0-1.52.5 1.64 1.64 0 0 0-.53 1.24 1.54 1.54 0 0 0 .1.58 1.24 1.24 0 0 0 .32.46 2.38 2.38 0 0 0 1.26.51l1.27.2a5.7 5.7 0 0 1 1.26.31 3.08 3.08 0 0 1 .83.51 2.56 2.56 0 0 1 .69 1 3.55 3.55 0 0 1 .22 1.3"
        />
        <path
          d="M263.73 49.95v-.8a2.53 2.53 0 0 1-.97.67 3.13 3.13 0 0 1-1.15.22 3.1 3.1 0 0 1-1.11-.18 2.53 2.53 0 0 1-.86-.54 2.43 2.43 0 0 1-.63-1 4.12 4.12 0 0 1-.17-1.24V42h1.6v4.85a1.74 1.74 0 0 0 .46 1.34 1.63 1.63 0 0 0 1.14.43 2.01 2.01 0 0 0 .64-.1 1.31 1.31 0 0 0 .53-.32 1.61 1.61 0 0 0 .35-.56 2.15 2.15 0 0 0 .13-.8V42h1.6v7.95Z"
        />
        <path d="M267.95 38.74h1.7v1.7h-1.7ZM268 42h1.6v7.95H268Z" />
        <path
          d="M278.2 47.52a2.3 2.3 0 0 1-.26 1.1 2.34 2.34 0 0 1-.69.8 3.03 3.03 0 0 1-1.05.47 5.3 5.3 0 0 1-1.32.16 6.86 6.86 0 0 1-1.8-.23 3.54 3.54 0 0 1-1.54-.92l1.05-1.05a2.38 2.38 0 0 0 1.09.67 4.57 4.57 0 0 0 1.2.16 2.62 2.62 0 0 0 1.24-.26.96.96 0 0 0 .26-1.48 1.3 1.3 0 0 0-.83-.3l-1.27-.1a3 3 0 0 1-1.74-.62 1.96 1.96 0 0 1-.61-1.58 2.17 2.17 0 0 1 .88-1.8 3.07 3.07 0 0 1 .95-.46 4.03 4.03 0 0 1 1.17-.17 6.33 6.33 0 0 1 1.65.2 3.2 3.2 0 0 1 1.3.7l-1.01 1.01a2.25 2.25 0 0 0-.89-.45 4.23 4.23 0 0 0-1.07-.13 1.75 1.75 0 0 0-1.09.28.9.9 0 0 0-.36.74.85.85 0 0 0 .23.6 1.38 1.38 0 0 0 .87.3l1.26.11a2.97 2.97 0 0 1 1.75.64 2.02 2.02 0 0 1 .62 1.6"
        />
        <path
          d="M286.1 47.52a2.3 2.3 0 0 1-.26 1.1 2.33 2.33 0 0 1-.69.8 3.03 3.03 0 0 1-1.05.47 5.3 5.3 0 0 1-1.32.16 6.86 6.86 0 0 1-1.8-.23 3.54 3.54 0 0 1-1.54-.92l1.05-1.05a2.38 2.38 0 0 0 1.09.67 4.57 4.57 0 0 0 1.2.16 2.62 2.62 0 0 0 1.24-.26.96.96 0 0 0 .26-1.48 1.3 1.3 0 0 0-.82-.3l-1.28-.1a3 3 0 0 1-1.74-.62 1.96 1.96 0 0 1-.61-1.58 2.21 2.21 0 0 1 .23-1.03 2.19 2.19 0 0 1 .65-.76 3.07 3.07 0 0 1 .95-.47 4.03 4.03 0 0 1 1.17-.17 6.33 6.33 0 0 1 1.65.2 3.2 3.2 0 0 1 1.3.7l-1 1.01a2.26 2.26 0 0 0-.9-.45 4.23 4.23 0 0 0-1.07-.13 1.75 1.75 0 0 0-1.09.28.9.9 0 0 0-.36.74.85.85 0 0 0 .23.6 1.38 1.38 0 0 0 .87.3l1.26.11a2.97 2.97 0 0 1 1.75.64 2.02 2.02 0 0 1 .62 1.6"
        />
        <path
          d="M289.38 46.44a2.4 2.4 0 0 0 .52 1.63 1.89 1.89 0 0 0 1.5.6 2.58 2.58 0 0 0 1.1-.2 3.1 3.1 0 0 0 .84-.62l1.02.96a5.84 5.84 0 0 1-.62.54 3.48 3.48 0 0 1-.66.38 3.52 3.52 0 0 1-.77.23 5.1 5.1 0 0 1-.94.08 4.52 4.52 0 0 1-1.4-.2 2.79 2.79 0 0 1-1.13-.7 3.35 3.35 0 0 1-.77-1.26 5.67 5.67 0 0 1-.28-1.91 4.44 4.44 0 0 1 .92-3 3.1 3.1 0 0 1 2.46-1.06 3.47 3.47 0 0 1 1.43.28 3.03 3.03 0 0 1 1.06.78 3.48 3.48 0 0 1 .66 1.21 5.05 5.05 0 0 1 .23 1.55v.7Zm3.58-1.11a5.03 5.03 0 0 0-.05-.6 2.02 2.02 0 0 0-.17-.49 1.6 1.6 0 0 0-.6-.73 1.7 1.7 0 0 0-.97-.27 1.75 1.75 0 0 0-.99.27 1.58 1.58 0 0 0-.6.73 1.7 1.7 0 0 0-.16.5 6.3 6.3 0 0 0-.04.58Z"
        />
        <path
          d="M302.71 47.52a2.3 2.3 0 0 1-.25 1.1 2.34 2.34 0 0 1-.69.8 3.03 3.03 0 0 1-1.05.47 5.3 5.3 0 0 1-1.32.16 6.86 6.86 0 0 1-1.8-.23 3.54 3.54 0 0 1-1.54-.92l1.05-1.05a2.38 2.38 0 0 0 1.09.67 4.57 4.57 0 0 0 1.2.16 2.62 2.62 0 0 0 1.24-.26.96.96 0 0 0 .26-1.48 1.3 1.3 0 0 0-.83-.3l-1.27-.1a3 3 0 0 1-1.74-.62 1.96 1.96 0 0 1-.61-1.58 2.21 2.21 0 0 1 .23-1.03 2.19 2.19 0 0 1 .65-.76 3.07 3.07 0 0 1 .95-.47 4.03 4.03 0 0 1 1.17-.17 6.33 6.33 0 0 1 1.65.2 3.2 3.2 0 0 1 1.3.7l-1.01 1.01a2.26 2.26 0 0 0-.89-.45 4.23 4.23 0 0 0-1.07-.13 1.75 1.75 0 0 0-1.09.28.9.9 0 0 0-.36.74.85.85 0 0 0 .23.6 1.38 1.38 0 0 0 .87.3l1.26.11a2.97 2.97 0 0 1 1.75.64 2.02 2.02 0 0 1 .62 1.6"
        />
        <path d="M76.4 59.29v3.4h4.66v1.52H76.4v4.72h-1.7V57.77h7.15v1.52Z" />
        <path
          d="M88.2 68.93v-.8a2.53 2.53 0 0 1-.96.67 3.13 3.13 0 0 1-1.16.22 3.1 3.1 0 0 1-1.1-.19 2.53 2.53 0 0 1-.86-.53 2.43 2.43 0 0 1-.63-1.01 4.13 4.13 0 0 1-.17-1.23v-5.08h1.6v4.84a1.75 1.75 0 0 0 .46 1.35 1.63 1.63 0 0 0 1.14.43 2.01 2.01 0 0 0 .64-.1 1.31 1.31 0 0 0 .52-.33 1.61 1.61 0 0 0 .36-.55 2.15 2.15 0 0 0 .13-.8v-4.84h1.6v7.95Z"
        />
        <path
          d="M97.33 68.93v-4.85a1.75 1.75 0 0 0-.46-1.34 1.63 1.63 0 0 0-1.14-.43 1.59 1.59 0 0 0-1.65 1.77v4.85h-1.6v-7.95h1.57v.8a2.54 2.54 0 0 1 .96-.67 3.15 3.15 0 0 1 1.16-.22 3.1 3.1 0 0 1 1.1.18 2.53 2.53 0 0 1 .86.54 2.46 2.46 0 0 1 .63 1 4.16 4.16 0 0 1 .17 1.24v5.08Z"
        />
        <path d="M101.5 57.72h1.7v1.7h-1.7Zm.06 3.26h1.6v7.95h-1.6Z" />
        <path d="M108.94 68.93h-1.3l-2.92-7.95h1.7l1.88 5.6 1.87-5.6h1.7Z" />
        <path d="M113.37 57.72h1.7v1.7h-1.7Zm.04 3.26h1.6v7.95h-1.6Z" />
        <path
          d="M118.9 65.42a2.4 2.4 0 0 0 .51 1.63 1.89 1.89 0 0 0 1.51.6 2.57 2.57 0 0 0 1.1-.2 3.09 3.09 0 0 0 .83-.62l1.02.95a5.85 5.85 0 0 1-.62.54 3.48 3.48 0 0 1-.66.39 3.52 3.52 0 0 1-.77.23 5.1 5.1 0 0 1-.93.08 4.52 4.52 0 0 1-1.4-.21 2.79 2.79 0 0 1-1.14-.7 3.35 3.35 0 0 1-.76-1.25 5.68 5.68 0 0 1-.28-1.92 4.44 4.44 0 0 1 .92-3 3.1 3.1 0 0 1 2.46-1.06 3.47 3.47 0 0 1 1.43.29 3.03 3.03 0 0 1 1.06.78 3.48 3.48 0 0 1 .66 1.2 5.05 5.05 0 0 1 .22 1.56v.7Zm3.58-1.12a5 5 0 0 0-.06-.58 2.02 2.02 0 0 0-.16-.5 1.59 1.59 0 0 0-.6-.73 1.7 1.7 0 0 0-.98-.27 1.75 1.75 0 0 0-.98.27 1.58 1.58 0 0 0-.6.73 1.7 1.7 0 0 0-.15.5 6.3 6.3 0 0 0-.05.58Z"
        />
        <path
          d="M137.45 65.75a3.11 3.11 0 0 1-.3 1.38 2.99 2.99 0 0 1-.84 1.03 3.74 3.74 0 0 1-1.27.64 5.64 5.64 0 0 1-1.62.22 6.7 6.7 0 0 1-2.21-.33 4.54 4.54 0 0 1-1.74-1.14l1.13-1.12a3.3 3.3 0 0 0 1.3.84 4.96 4.96 0 0 0 1.55.23 2.82 2.82 0 0 0 1.7-.45 1.68 1.68 0 0 0 .17-2.42 1.46 1.46 0 0 0-.47-.3 4.23 4.23 0 0 0-.76-.17l-1.32-.19a3.78 3.78 0 0 1-2.12-.89 2.79 2.79 0 0 1-.81-2.13 3.32 3.32 0 0 1 .25-1.33 3 3 0 0 1 .74-1.04 3.26 3.26 0 0 1 1.17-.67 4.88 4.88 0 0 1 1.57-.24 5.65 5.65 0 0 1 1.94.3 4.54 4.54 0 0 1 1.53.97l-1.07 1.07a2.73 2.73 0 0 0-1.12-.68 4.56 4.56 0 0 0-1.31-.18 2.13 2.13 0 0 0-1.52.49 1.64 1.64 0 0 0-.52 1.25 1.54 1.54 0 0 0 .1.57 1.24 1.24 0 0 0 .32.46 2.38 2.38 0 0 0 1.25.52l1.28.19a5.7 5.7 0 0 1 1.25.32 3.08 3.08 0 0 1 .84.5 2.56 2.56 0 0 1 .68 1 3.54 3.54 0 0 1 .23 1.3"
        />
        <path d="M142.77 68.93h-1.3l-2.92-7.95h1.7l1.88 5.6 1.87-5.6h1.7Z" />
        <path d="M147.2 57.72h1.7v1.7h-1.7Zm.05 3.26h1.6v7.95h-1.6Z" />
        <path
          d="M150.96 68.93v-1.27l3.96-5.25h-3.74v-1.43h5.71v1.27l-3.99 5.25h4v1.43Z"
        />
        <path
          d="M158.48 68.93v-1.27l3.96-5.25h-3.74v-1.43h5.72v1.27l-4 5.25h4v1.43Z"
        />
        <path
          d="M167.77 65.42a2.4 2.4 0 0 0 .52 1.63 1.89 1.89 0 0 0 1.51.6 2.57 2.57 0 0 0 1.1-.2 3.09 3.09 0 0 0 .83-.62l1.02.95a5.77 5.77 0 0 1-.62.54 3.45 3.45 0 0 1-.66.39 3.52 3.52 0 0 1-.77.23 5.1 5.1 0 0 1-.93.08 4.52 4.52 0 0 1-1.4-.21 2.79 2.79 0 0 1-1.14-.7 3.35 3.35 0 0 1-.76-1.25 5.68 5.68 0 0 1-.28-1.92 4.44 4.44 0 0 1 .92-3 3.1 3.1 0 0 1 2.45-1.06 3.47 3.47 0 0 1 1.43.29 3.03 3.03 0 0 1 1.06.78 3.48 3.48 0 0 1 .66 1.2 5.05 5.05 0 0 1 .23 1.56v.7Zm3.59-1.12a5 5 0 0 0-.06-.58 2.02 2.02 0 0 0-.16-.5 1.59 1.59 0 0 0-.6-.73 1.7 1.7 0 0 0-.98-.27 1.75 1.75 0 0 0-.98.27 1.58 1.58 0 0 0-.6.73 1.7 1.7 0 0 0-.15.5 6.3 6.3 0 0 0-.05.58Z"
        />
        <path
          d="M179.6 62.85a2.16 2.16 0 0 0-.52-.4 1.45 1.45 0 0 0-.66-.14 1.5 1.5 0 0 0-1.13.48 1.8 1.8 0 0 0-.46 1.31v4.83h-1.6v-7.95h1.57v.86a2.27 2.27 0 0 1 .88-.69 2.87 2.87 0 0 1 1.24-.26 2.92 2.92 0 0 1 1.04.17 2.48 2.48 0 0 1 .86.58Z"
        />
        <path
          d="M182.97 65.42a2.4 2.4 0 0 0 .52 1.63 1.89 1.89 0 0 0 1.5.6 2.57 2.57 0 0 0 1.1-.2 3.09 3.09 0 0 0 .84-.62l1.02.95a5.84 5.84 0 0 1-.62.54 3.47 3.47 0 0 1-.66.39 3.52 3.52 0 0 1-.77.23 5.1 5.1 0 0 1-.94.08 4.52 4.52 0 0 1-1.4-.21 2.79 2.79 0 0 1-1.13-.7 3.35 3.35 0 0 1-.76-1.25 5.68 5.68 0 0 1-.29-1.92 4.44 4.44 0 0 1 .92-3 3.1 3.1 0 0 1 2.46-1.06 3.47 3.47 0 0 1 1.43.29 3.03 3.03 0 0 1 1.06.78 3.48 3.48 0 0 1 .66 1.2 5.05 5.05 0 0 1 .23 1.56v.7Zm3.58-1.12a5.02 5.02 0 0 0-.05-.58 2.02 2.02 0 0 0-.17-.5 1.59 1.59 0 0 0-.6-.73 1.7 1.7 0 0 0-.97-.27 1.75 1.75 0 0 0-.98.27 1.58 1.58 0 0 0-.6.73 1.7 1.7 0 0 0-.16.5 6.3 6.3 0 0 0-.05.58Z"
        />
        <path
          d="M82.53 80.13a3.57 3.57 0 0 1-.25 1.36 3.01 3.01 0 0 1-.72 1.06 3.47 3.47 0 0 1-1.13.7 4.01 4.01 0 0 1-1.48.26h-2.54v4.4h-1.7V76.73h4.24a4.01 4.01 0 0 1 1.47.26 3.46 3.46 0 0 1 1.14.7 3 3 0 0 1 .72 1.08 3.58 3.58 0 0 1 .25 1.35m-1.7 0a1.76 1.76 0 0 0-.54-1.38 2.08 2.08 0 0 0-1.44-.49H76.4v3.72h2.45a2.1 2.1 0 0 0 1.44-.48 1.73 1.73 0 0 0 .54-1.37"
        />
        <path
          d="M85.58 84.4a2.4 2.4 0 0 0 .52 1.62 1.89 1.89 0 0 0 1.5.6 2.57 2.57 0 0 0 1.1-.2 3.09 3.09 0 0 0 .84-.62l1.02.96a5.77 5.77 0 0 1-.62.54 3.45 3.45 0 0 1-.66.39 3.52 3.52 0 0 1-.77.23 5.1 5.1 0 0 1-.94.08 4.52 4.52 0 0 1-1.4-.21 2.78 2.78 0 0 1-1.13-.7 3.35 3.35 0 0 1-.76-1.26 5.67 5.67 0 0 1-.29-1.9 4.44 4.44 0 0 1 .92-3 3.1 3.1 0 0 1 2.46-1.07 3.47 3.47 0 0 1 1.43.28 3.03 3.03 0 0 1 1.06.79 3.47 3.47 0 0 1 .66 1.2 5.03 5.03 0 0 1 .23 1.56v.7Zm3.58-1.12a5 5 0 0 0-.05-.59 2.02 2.02 0 0 0-.17-.5 1.6 1.6 0 0 0-.6-.72 1.7 1.7 0 0 0-.97-.27 1.75 1.75 0 0 0-.98.27 1.58 1.58 0 0 0-.6.73 1.7 1.7 0 0 0-.16.5 6.3 6.3 0 0 0-.05.58Z"
        />
        <path
          d="M97.88 87.9v-4.84a1.75 1.75 0 0 0-.46-1.35 1.63 1.63 0 0 0-1.14-.42 1.59 1.59 0 0 0-1.65 1.77v4.85h-1.6v-7.95h1.57v.8a2.54 2.54 0 0 1 .97-.68 3.15 3.15 0 0 1 1.15-.22 3.1 3.1 0 0 1 1.1.2 2.53 2.53 0 0 1 .86.53 2.46 2.46 0 0 1 .63 1 4.16 4.16 0 0 1 .17 1.24v5.08Z"
        />
        <path
          d="M106.68 87.9v-.84a2.44 2.44 0 0 1-.95.74 3.14 3.14 0 0 1-1.16.2 3.18 3.18 0 0 1-1.1-.18 2.27 2.27 0 0 1-.85-.52 2.34 2.34 0 0 1-.48-.7 3.86 3.86 0 0 1-.27-.83 5.74 5.74 0 0 1-.12-.92 19.35 19.35 0 0 1 0-1.85 5.59 5.59 0 0 1 .12-.9 3.92 3.92 0 0 1 .27-.84 2.34 2.34 0 0 1 .48-.69 2.27 2.27 0 0 1 .84-.51 3.1 3.1 0 0 1 1.1-.2 3.23 3.23 0 0 1 1.14.2 2.32 2.32 0 0 1 .94.7v-4.02h1.6v11.17Zm-.04-3.98a9.14 9.14 0 0 0-.05-1 2.66 2.66 0 0 0-.23-.85 1.38 1.38 0 0 0-.5-.57 1.9 1.9 0 0 0-1.73 0 1.44 1.44 0 0 0-.5.57 2.5 2.5 0 0 0-.24.84 9.26 9.26 0 0 0 0 2.03 2.57 2.57 0 0 0 .24.84 1.43 1.43 0 0 0 .5.58 1.89 1.89 0 0 0 1.73 0 1.37 1.37 0 0 0 .5-.58 2.74 2.74 0 0 0 .23-.85 9.13 9.13 0 0 0 .05-1"
        />
        <path d="M110.9 76.7h1.7v1.69h-1.7Zm.05 3.26h1.6v7.95h-1.6Z" />
        <path
          d="M121.02 86.87a3.67 3.67 0 0 1-1.18.86 3.61 3.61 0 0 1-1.45.27 4 4 0 0 1-1.3-.22 2.98 2.98 0 0 1-1.14-.7 3.58 3.58 0 0 1-.8-1.27 5.97 5.97 0 0 1 0-3.77 3.51 3.51 0 0 1 .8-1.26 3.04 3.04 0 0 1 1.13-.7 4.02 4.02 0 0 1 1.31-.22 3.7 3.7 0 0 1 1.45.26 3.58 3.58 0 0 1 1.18.85l-1.1 1.07a2.69 2.69 0 0 0-.72-.58 1.77 1.77 0 0 0-.8-.17 1.75 1.75 0 0 0-1.48.7 1.87 1.87 0 0 0-.37.78 5.36 5.36 0 0 0-.1 1.15 5.46 5.46 0 0 0 .1 1.16 1.88 1.88 0 0 0 .37.79 1.75 1.75 0 0 0 1.47.7 1.77 1.77 0 0 0 .81-.17 2.68 2.68 0 0 0 .72-.58Z"
        />
        <path
          d="M127.84 87.9v-.8a2.53 2.53 0 0 1-.97.68 3.13 3.13 0 0 1-1.15.22 3.1 3.1 0 0 1-1.11-.19 2.53 2.53 0 0 1-.86-.53 2.43 2.43 0 0 1-.62-1.01 4.12 4.12 0 0 1-.18-1.23v-5.08h1.6v4.84a1.75 1.75 0 0 0 .47 1.35 1.63 1.63 0 0 0 1.13.42 2.01 2.01 0 0 0 .64-.1 1.31 1.31 0 0 0 .53-.32 1.61 1.61 0 0 0 .35-.56 2.15 2.15 0 0 0 .13-.79v-4.84h1.6v7.95Z"
        />
        <path
          d="M134.24 87.9a2.43 2.43 0 0 1-.98-.17 1.93 1.93 0 0 1-.68-.48 1.85 1.85 0 0 1-.4-.7 2.76 2.76 0 0 1-.12-.84v-8.97h1.6v8.88a1.08 1.08 0 0 0 .19.69.82.82 0 0 0 .67.23h.7v1.37Z"
        />
        <path
          d="M141.44 87.9v-.72a2.75 2.75 0 0 1-.91.63 3.3 3.3 0 0 1-1.21.19 3.82 3.82 0 0 1-1.27-.18 2.29 2.29 0 0 1-.87-.54 2.37 2.37 0 0 1-.63-1.71 2.16 2.16 0 0 1 .69-1.65 2.92 2.92 0 0 1 2.06-.64h2.1v-.56a1.45 1.45 0 0 0-.4-1.13 1.97 1.97 0 0 0-1.34-.36 2.54 2.54 0 0 0-1.03.17 1.96 1.96 0 0 0-.71.6l-1.05-.99a3.01 3.01 0 0 1 1.21-.9 4.74 4.74 0 0 1 1.65-.24q3.28 0 3.28 2.72v5.32Zm-.03-3.5h-1.87a1.65 1.65 0 0 0-1.07.3 1.03 1.03 0 0 0-.36.84 1.02 1.02 0 0 0 .35.84 1.73 1.73 0 0 0 1.1.29 4.23 4.23 0 0 0 .83-.07 1.31 1.31 0 0 0 .67-.39 1.63 1.63 0 0 0 .35-1.17Z"
        />
        <path
          d="M150.01 81.82a2.16 2.16 0 0 0-.52-.4 1.45 1.45 0 0 0-.67-.13 1.5 1.5 0 0 0-1.12.48 1.8 1.8 0 0 0-.46 1.3v4.84h-1.6v-7.95h1.57v.86a2.27 2.27 0 0 1 .88-.69 2.87 2.87 0 0 1 1.24-.27 2.92 2.92 0 0 1 1.03.18 2.48 2.48 0 0 1 .87.57Z"
        />
        <path
          d="M156.82 87.9v-.72a2.75 2.75 0 0 1-.91.63 3.3 3.3 0 0 1-1.21.19 3.82 3.82 0 0 1-1.27-.18 2.29 2.29 0 0 1-.87-.54 2.37 2.37 0 0 1-.63-1.71 2.16 2.16 0 0 1 .68-1.65 2.92 2.92 0 0 1 2.07-.64h2.1v-.56a1.45 1.45 0 0 0-.4-1.13 1.97 1.97 0 0 0-1.34-.36 2.54 2.54 0 0 0-1.03.17 1.96 1.96 0 0 0-.71.6l-1.05-.99a3 3 0 0 1 1.21-.9 4.73 4.73 0 0 1 1.64-.24q3.29 0 3.29 2.72v5.32Zm-.04-3.5h-1.87a1.65 1.65 0 0 0-1.06.3 1.03 1.03 0 0 0-.36.84 1.02 1.02 0 0 0 .35.84 1.73 1.73 0 0 0 1.1.29 4.23 4.23 0 0 0 .82-.07 1.31 1.31 0 0 0 .68-.39 1.63 1.63 0 0 0 .35-1.17Z"
        />
        <path
          d="M166.9 85.48a2.3 2.3 0 0 1-.25 1.1 2.33 2.33 0 0 1-.7.79 3.03 3.03 0 0 1-1.04.48 5.3 5.3 0 0 1-1.32.15 6.86 6.86 0 0 1-1.8-.22 3.54 3.54 0 0 1-1.55-.92l1.05-1.05a2.38 2.38 0 0 0 1.1.67 4.57 4.57 0 0 0 1.2.16 2.62 2.62 0 0 0 1.24-.27.96.96 0 0 0 .26-1.48 1.3 1.3 0 0 0-.83-.29l-1.27-.1a3 3 0 0 1-1.74-.63 1.96 1.96 0 0 1-.62-1.57 2.21 2.21 0 0 1 .24-1.04 2.19 2.19 0 0 1 .64-.76 3.07 3.07 0 0 1 .95-.47 4.03 4.03 0 0 1 1.17-.16 6.34 6.34 0 0 1 1.65.2 3.2 3.2 0 0 1 1.3.69l-1 1.02a2.26 2.26 0 0 0-.9-.45 4.22 4.22 0 0 0-1.07-.13 1.75 1.75 0 0 0-1.08.28.9.9 0 0 0-.36.74.85.85 0 0 0 .23.6 1.37 1.37 0 0 0 .87.3l1.25.11a2.97 2.97 0 0 1 1.76.64 2.02 2.02 0 0 1 .62 1.6"
        />
        <path
          d="M180.11 84.72a3.11 3.11 0 0 1-.3 1.38 2.99 2.99 0 0 1-.83 1.04 3.74 3.74 0 0 1-1.27.64 5.64 5.64 0 0 1-1.62.22 6.7 6.7 0 0 1-2.22-.33 4.54 4.54 0 0 1-1.74-1.14l1.13-1.12a3.3 3.3 0 0 0 1.3.84 4.96 4.96 0 0 0 1.56.23 2.82 2.82 0 0 0 1.7-.45 1.68 1.68 0 0 0 .16-2.42 1.46 1.46 0 0 0-.47-.3 4.23 4.23 0 0 0-.75-.17l-1.32-.19a3.78 3.78 0 0 1-2.12-.9 2.79 2.79 0 0 1-.82-2.13 3.32 3.32 0 0 1 .26-1.32 3 3 0 0 1 .74-1.04 3.26 3.26 0 0 1 1.17-.68 4.88 4.88 0 0 1 1.57-.23 5.65 5.65 0 0 1 1.93.3 4.54 4.54 0 0 1 1.54.97L178.63 79a2.73 2.73 0 0 0-1.12-.69 4.56 4.56 0 0 0-1.31-.18 2.13 2.13 0 0 0-1.52.5 1.64 1.64 0 0 0-.53 1.24 1.53 1.53 0 0 0 .1.58 1.24 1.24 0 0 0 .33.46 2.38 2.38 0 0 0 1.25.51l1.28.2a5.7 5.7 0 0 1 1.25.31 3.08 3.08 0 0 1 .84.51 2.56 2.56 0 0 1 .68 1 3.54 3.54 0 0 1 .23 1.3"
        />
        <path d="M185.44 87.9h-1.3l-2.92-7.94h1.7l1.88 5.6 1.87-5.6h1.7Z" />
        <path d="M189.87 76.7h1.7v1.69h-1.7Zm.04 3.26h1.6v7.95h-1.6Z" />
        <path
          d="M193.62 87.9v-1.26l3.96-5.26h-3.74v-1.42h5.72v1.27l-4 5.25h4v1.43Z"
        />
        <path
          d="M206.13 81.82a2.16 2.16 0 0 0-.53-.4 1.45 1.45 0 0 0-.67-.13 1.5 1.5 0 0 0-1.12.48 1.8 1.8 0 0 0-.46 1.3v4.84h-1.6v-7.95h1.57v.86a2.27 2.27 0 0 1 .88-.69 2.87 2.87 0 0 1 1.24-.27 2.92 2.92 0 0 1 1.04.18 2.48 2.48 0 0 1 .86.57Z"
        />
        <path
          d="M212.93 87.9v-.72a2.75 2.75 0 0 1-.91.63 3.3 3.3 0 0 1-1.21.19 3.82 3.82 0 0 1-1.27-.18 2.29 2.29 0 0 1-.87-.54 2.37 2.37 0 0 1-.63-1.71 2.16 2.16 0 0 1 .69-1.65 2.92 2.92 0 0 1 2.06-.64h2.1v-.56a1.45 1.45 0 0 0-.4-1.13 1.97 1.97 0 0 0-1.34-.36 2.54 2.54 0 0 0-1.03.17 1.96 1.96 0 0 0-.71.6l-1.05-.99a3.01 3.01 0 0 1 1.21-.9 4.74 4.74 0 0 1 1.65-.24q3.28 0 3.28 2.72v5.32Zm-.04-3.5h-1.87a1.65 1.65 0 0 0-1.06.3 1.03 1.03 0 0 0-.36.84 1.02 1.02 0 0 0 .35.84 1.73 1.73 0 0 0 1.1.29 4.23 4.23 0 0 0 .82-.07 1.31 1.31 0 0 0 .68-.39 1.63 1.63 0 0 0 .35-1.17Z"
        />
        <path
          d="M223.01 85.48a2.3 2.3 0 0 1-.25 1.1 2.33 2.33 0 0 1-.7.79 3.03 3.03 0 0 1-1.04.48 5.3 5.3 0 0 1-1.32.15 6.86 6.86 0 0 1-1.8-.22 3.54 3.54 0 0 1-1.55-.92l1.05-1.05a2.38 2.38 0 0 0 1.1.67 4.57 4.57 0 0 0 1.2.16 2.62 2.62 0 0 0 1.24-.27.96.96 0 0 0 .26-1.48 1.3 1.3 0 0 0-.83-.29l-1.27-.1a3 3 0 0 1-1.75-.63 1.96 1.96 0 0 1-.6-1.57 2.21 2.21 0 0 1 .23-1.04 2.19 2.19 0 0 1 .64-.76 3.07 3.07 0 0 1 .95-.47 4.03 4.03 0 0 1 1.17-.16 6.33 6.33 0 0 1 1.65.2 3.2 3.2 0 0 1 1.3.69l-1 1.02a2.26 2.26 0 0 0-.9-.45 4.23 4.23 0 0 0-1.07-.13 1.75 1.75 0 0 0-1.08.28.9.9 0 0 0-.36.74.85.85 0 0 0 .23.6 1.37 1.37 0 0 0 .87.3l1.25.11a2.97 2.97 0 0 1 1.75.64 2.02 2.02 0 0 1 .63 1.6"
        />
      </g>
    </g>
  </svg>
</template>
