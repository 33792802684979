<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    aria-hidden="true"
  >
    <g id="Group_2" data-name="Group 2" opacity="0.996">
      <rect
        id="Rectangle_8"
        data-name="Rectangle 8"
        width="18"
        height="18"
        fill="none"
      />
      <circle
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx="4.5"
        cy="4.5"
        r="4.5"
        transform="translate(4 2)"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="1.5"
      />
      <path
        id="Path_1"
        data-name="Path 1"
        d="M30.989,163.836a7.676,7.676,0,0,1,13.293,0"
        transform="translate(-28.866 -149.038)"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>
