<template>
  <NuxtLink
    :to="data.page.url"
    class="ring-offset-0"
    :class="{
      'px-4 text-white transition-colors hover:text-blue-800 nav-white:text-black':
        data.page.url,
    }"
  >
    {{ data.page.title }}
  </NuxtLink>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
