<template>
  <DevOnly>
    <div
      v-if="showBreaky"
      class="fixed bottom-10 right-10 z-top flex h-10 w-10 items-center justify-center rounded-full bg-black text-white"
    >
      <span class="xs:hidden"> - </span>
      <span class="hidden xs:inline sm:hidden">xs</span>
      <span class="hidden sm:inline md:hidden">sm</span>
      <span class="hidden md:inline lg:hidden">md</span>
      <span class="hidden lg:inline nav:hidden">lg</span>
      <span class="hidden nav:inline xl:hidden">nav</span>
      <span class="hidden xl:inline 2xl:hidden">xl</span>
      <span class="hidden 2xl:inline 3xl:hidden">2xl</span>
      <span class="hidden 3xl:inline">3xl</span>
    </div>
  </DevOnly>
</template>

<script setup>
const config = useRuntimeConfig()
const showBreaky = computed(() => config.public.APP_ENV !== 'production')
</script>
