import { default as _91_46_46_46uri_93FmSYeNXvC5Meta } from "/vercel/path0/pages/[...uri].vue?macro=true";
import { default as createJ2yTq1FiFUMeta } from "/vercel/path0/pages/auth/index/jobs/create.vue?macro=true";
import { default as editFCq5MSJSbEMeta } from "/vercel/path0/pages/auth/index/jobs/edit.vue?macro=true";
import { default as index6Rqqv8xohOMeta } from "/vercel/path0/pages/auth/index/jobs/index.vue?macro=true";
import { default as editEx8Lq8UQ6fMeta } from "/vercel/path0/pages/auth/index/me/edit.vue?macro=true";
import { default as indexubuducaZmfMeta } from "/vercel/path0/pages/auth/index/me/index.vue?macro=true";
import { default as indexkppHktB81xMeta } from "/vercel/path0/pages/auth/index.vue?macro=true";
import { default as loginuTy3SYY7xlMeta } from "/vercel/path0/pages/protected/login.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
export default [
  {
    name: "uri___de",
    path: "/:uri(.*)*",
    meta: _91_46_46_46uri_93FmSYeNXvC5Meta || {},
    component: () => import("/vercel/path0/pages/[...uri].vue").then(m => m.default || m)
  },
  {
    name: "uri___fr",
    path: "/fr/:uri(.*)*",
    meta: _91_46_46_46uri_93FmSYeNXvC5Meta || {},
    component: () => import("/vercel/path0/pages/[...uri].vue").then(m => m.default || m)
  },
  {
    name: "auth___de",
    path: "/auth",
    meta: indexkppHktB81xMeta || {},
    component: () => import("/vercel/path0/pages/auth/index.vue").then(m => m.default || m),
    children: [
  {
    name: "auth-index-jobs-create___de",
    path: "jobs/create",
    component: () => import("/vercel/path0/pages/auth/index/jobs/create.vue").then(m => m.default || m)
  },
  {
    name: "auth-index-jobs-edit___de",
    path: "jobs/edit",
    component: () => import("/vercel/path0/pages/auth/index/jobs/edit.vue").then(m => m.default || m)
  },
  {
    name: "auth-index-jobs___de",
    path: "jobs",
    component: () => import("/vercel/path0/pages/auth/index/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-index-me-edit___de",
    path: "me/edit",
    component: () => import("/vercel/path0/pages/auth/index/me/edit.vue").then(m => m.default || m)
  },
  {
    name: "auth-index-me___de",
    path: "me",
    component: () => import("/vercel/path0/pages/auth/index/me/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth___fr",
    path: "/fr/auth",
    meta: indexkppHktB81xMeta || {},
    component: () => import("/vercel/path0/pages/auth/index.vue").then(m => m.default || m),
    children: [
  {
    name: "auth-index-jobs-create___fr",
    path: "jobs/create",
    component: () => import("/vercel/path0/pages/auth/index/jobs/create.vue").then(m => m.default || m)
  },
  {
    name: "auth-index-jobs-edit___fr",
    path: "jobs/edit",
    component: () => import("/vercel/path0/pages/auth/index/jobs/edit.vue").then(m => m.default || m)
  },
  {
    name: "auth-index-jobs___fr",
    path: "jobs",
    component: () => import("/vercel/path0/pages/auth/index/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-index-me-edit___fr",
    path: "me/edit",
    component: () => import("/vercel/path0/pages/auth/index/me/edit.vue").then(m => m.default || m)
  },
  {
    name: "auth-index-me___fr",
    path: "me",
    component: () => import("/vercel/path0/pages/auth/index/me/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "protected-login___de",
    path: "/protected/login",
    component: () => import("/vercel/path0/pages/protected/login.vue").then(m => m.default || m)
  },
  {
    name: "protected-login___fr",
    path: "/fr/protected/login",
    component: () => import("/vercel/path0/pages/protected/login.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/search",
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___fr",
    path: "/fr/search",
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m)
  }
]