export const useApi = async (url: string, options: any = null) => {
  const config = useRuntimeConfig()
  const headers = useRequestHeaders(['cookie'])

  const { data } = await useFetch(`${config.public.API_URL}/api${url}`, {
    ...options,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Origin: config.public.APP_URL,
      ...headers,
    },
  })

  return data
}
