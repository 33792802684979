<template>
  <nav
    class="fixed inset-x-0 top-0 z-top"
    :data-nav-white="
      navDropdownIsOpen || isWhite || thresholdCrossed || navIsWhite
    "
  >
    <Container
      class="flex items-start justify-between py-6 transition-all duration-150 ease-linear nav:items-center"
      :class="[
        { 'bg-white': navDropdownIsOpen || thresholdCrossed || navIsWhite },
        { '!py-3': thresholdCrossed },
      ]"
    >
      <NuxtLink
        :to="localePath('/')"
        class="relative z-top -m-2 rounded-sm p-2 ring-offset-0"
        :aria-label="$t('accessibility.link_to_homepage')"
      >
        <LogoIcon
          :class="[
            'h-12 origin-top-left pb-1 text-white transition-transform duration-300 sm:h-16',
            { 'scale-90': thresholdCrossed || mobileMenuIsOpen },
            {
              '!text-black':
                navDropdownIsOpen ||
                thresholdCrossed ||
                navIsWhite ||
                isWhite ||
                mobileMenuIsOpen ||
                blackLogo,
            },
          ]"
        />
      </NuxtLink>

      <!-- Meta Navigation -->
      <div class="meta hidden flex-col items-end nav:flex">
        <ul
          class="transition-custom -mr-1 flex h-9 space-x-6 overflow-hidden px-1 duration-150 ease-in"
          :class="{ '!h-0 opacity-0': thresholdCrossed && !childHasFocus }"
        >
          <li v-for="{ page } in metaLinks" :key="page.id">
            <NuxtLink
              :to="page.url"
              :target="$useTarget(page.url)"
              class="text-sm uppercase tracking-wide text-white/[0.65] ring-offset-0 transition-colors hover:!text-blue-800 nav-white:text-blue-dark-900/80"
              @focus="childHasFocus = true"
              @blur="childHasFocus = false"
            >
              {{ page.title }}
            </NuxtLink>
          </li>
          <ClientOnly>
            <LangSwitcher
              key="lang-switcher"
              class="mt-[0.125rem] text-white/[0.65] nav-white:text-blue-dark-900/80"
            />
          </ClientOnly>
        </ul>

        <!-- Desktop Navigation -->
        <ul
          class="nav-items flex items-center text-md font-medium"
          @mouseleave="setNavTransparent"
        >
          <li
            v-for="(item, index) in mainLinks"
            :key="index"
            @mouseenter="parentNavItemEntered($event, item)"
          >
            <Component
              :is="
                item.page.blueprint === 'section' ? 'NavDropdown' : 'NavItem'
              "
              :data="item"
              :index="index"
              :nav-dropdown-is-open="navDropdownIsOpen"
              :threshold-crossed="thresholdCrossed"
              @dropdown-opened="dropdownOpened"
              @dropdown-closed="dropdownClosed"
            />
          </li>
          <li class="inline-block px-4">
            <NuxtLink
              class="-m-1 block p-1 ring-offset-0"
              to="/search"
              :aria-label="$t('accessibility.link_to_global_page_search')"
            >
              <SearchIcon
                class="w-4 text-white transition-colors duration-150 hover:text-blue-800 nav-white:text-black"
              />
            </NuxtLink>
          </li>
          <li class="inline-block pl-4">
            <NuxtLink
              class="-m-1 block p-1 ring-offset-0"
              :to="localePath('/login')"
              :aria-label="$t('accessibility.link_to_login_page_for_members')"
            >
              <UserIcon
                class="w-5 text-white transition-colors duration-150 hover:text-blue-800 nav-white:text-black"
              />
            </NuxtLink>
          </li>
        </ul>
      </div>

      <div class="flex items-center nav:hidden">
        <NuxtLink :to="localePath('/login')" class="flex text-md">
          <UserIcon
            class="mr-5 mt-1.5 w-6 text-white sm:mt-2 sm:w-8"
            :class="{
              '!text-blue-800': thresholdCrossed || isWhite || navIsWhite,
            }"
          />
        </NuxtLink>

        <!-- Mobile Navigation Button -->
        <MobileMenuButton
          :threshold-crossed="thresholdCrossed || isWhite || navIsWhite"
        />
      </div>

      <!-- Mobile Navigation Menu -->
      <Transition name="fade">
        <MobileMenu
          v-show="mobileMenuIsOpen"
          :nav-items="mainLinks"
          :meta="metaLinks"
          class="nav:hidden"
        />
      </Transition>
    </Container>
  </nav>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useNavigationStore } from '~/stores/navigation'
import { useGlobalsStore } from '~/stores/globals'

export default {
  setup() {
    const { isWhite, blackLogo, mobileMenuIsOpen } = storeToRefs(
      useNavigationStore()
    )
    const { mainLinks, metaLinks } = storeToRefs(useGlobalsStore())

    return {
      navIsWhite: isWhite,
      mobileMenuIsOpen,
      mainLinks,
      metaLinks,
      blackLogo,
    }
  },

  data() {
    return {
      thresholdCrossed: false,
      showBtn: false,
      navDropdownIsOpen: false,
      isWhite: false,
      childHasFocus: false,
      timer: null,
    }
  },

  mounted() {
    this.registerScrollEvent()
  },

  methods: {
    parentNavItemEntered(event, item) {
      if (item.children.length === 0) {
        return
      }

      this.canSetWhite(item)

      const siblings = [...event.target.parentNode.children]

      // remove specific element from all siblings by index
      siblings.forEach((node) => {
        if (node !== event.target) {
          node.classList.add('pointer-events-none')
        }
      })
    },

    async setNavTransparent() {
      this.timer = setTimeout(() => {
        this.dropdownClosed()
      }, 200)
    },

    dropdownOpened() {
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.navDropdownIsOpen = true
    },

    dropdownClosed() {
      this.navDropdownIsOpen = false
      this.isWhite = false
    },

    registerScrollEvent() {
      const { y } = useScroll(window)

      watchEffect(() => {
        this.thresholdCrossed = y.value > 100
      })
    },

    canSetWhite(item) {
      if (item.page.blueprint === 'section' && item.children.length > 0) {
        this.isWhite = true
      }
    },
  },
}
</script>

<style scoped lang="scss">
.meta {
  .router-link-exact-active {
    @apply text-blue-800;
  }
}

.nav-items {
  a {
    @apply transition-colors duration-150;
  }

  a:hover {
    @apply text-blue-800;
  }
}

.transition-custom {
  transition-property: height, opacity;
}
</style>
