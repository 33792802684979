<template>
  <div>
    <ClientOnly>
      <Popper
        arrow
        placement="top"
        :show="showPopper"
        @mouseenter="showPopper = true"
        @mouseleave=";(showPopper = false), (showOptions = false)"
      >
        <button v-bind="$attrs" @click="showOptions = true">
          <slot />
        </button>
        <template #content>
          <div>
            <transition name="fade" mode="out-in">
              <p v-if="!showOptions" key="0" class="whitespace-nowrap">
                {{ $t('save_to_calendar') }}
              </p>
              <div
                v-else
                key="1"
                class="flex flex-col items-start gap-3 whitespace-nowrap p-2"
              >
                <!-- GOOGLE -->
                <button
                  :aria-label="`${$t('accessibility.save_to')} Google ${$t(
                    'calendar'
                  )}`"
                  class="group flex items-center gap-2"
                  @click="generateGoogleCalendarLink()"
                >
                  <GoogleCalendarIcon class="mr-2 w-4" />
                  <span class="transition-colors group-hover:text-blue-800">
                    Google
                  </span>
                </button>
                <!-- APPLE -->
                <button
                  :aria-label="`${$t('accessibility.save_to')} Apple ${$t(
                    'calendar'
                  )}`"
                  class="group flex items-center gap-2"
                  @click="generateAppleCalendarLink()"
                >
                  <AppleIcon class="mr-2 w-4" />
                  <span class="transition-colors group-hover:text-blue-800">
                    Apple
                  </span>
                </button>
                <!-- OUTLOOK -->
                <button
                  :aria-label="`${$t('accessibility.save_to')} Outlook ${$t(
                    'calendar'
                  )}`"
                  class="group flex items-center gap-2"
                  @click="generateOutlookCalendarLink"
                >
                  <OutlookIcon class="mr-2 w-4" />
                  <span class="transition-colors group-hover:text-blue-800">
                    Outlook.com
                  </span>
                </button>
                <!-- ICS FILE -->
                <button
                  :aria-label="$t('accessibility.download_ics_file')"
                  class="group flex items-center gap-2"
                  @click="generateIcsFile"
                >
                  <CalendarIcon class="mr-2 w-4" />
                  <span class="transition-colors group-hover:text-blue-800">
                    ICS File
                  </span>
                </button>
              </div>
            </transition>
          </div>
        </template>
      </Popper>
    </ClientOnly>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    start: {
      type: String,
      required: true,
    },

    end: {
      type: String,
      required: true,
    },

    location: {
      type: String,
      default: '',
    },

    description: {
      type: String,
      default: '',
    },

    url: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      showPopper: false,
      showOptions: false,
    }
  },

  computed: {
    event() {
      return {
        title: this.$removeHtmlTags(this.title),
        start: new Date(this.start),
        end: new Date(this.end),
        location: this.location,
        description: `${this.description} \n\n ${this.url}`,
        url: this.url,
      }
    },
  },

  mounted() {
    // no need for the tooltip on mobile
    if (window.innerWidth < 640) {
      this.showOptions = true
    }
  },

  methods: {
    formatDateForUrl(date) {
      return encodeURIComponent(date.toISOString().replace(/-|:|\.\d+/g, ''))
    },

    generateGoogleCalendarLink() {
      const link = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
        this.event.title
      )}&dates=${this.formatDateForUrl(
        this.event.start
      )}/${this.formatDateForUrl(this.event.end)}&details=${encodeURIComponent(
        this.event.description
      )}&location=${encodeURIComponent(
        this.event.location
      )}&sprop=url:${encodeURIComponent(this.event.url)}`

      window.open(link, '_blank')
    },

    generateAppleCalendarLink() {
      // Apple Calendar doesn't support direct web links for creating events.
      // We need to create and download an ICS file similar to the generateIcsFile function.
      // Users can then open the ICS file to add the event to their Apple Calendar.
      this.generateIcsFile()
    },

    generateMicrosoft365CalendarLink() {
      const link = `https://outlook.office.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(
        this.event.title
      )}&startdt=${this.formatDateForUrl(
        this.event.start
      )}&enddt=${this.formatDateForUrl(
        this.event.end
      )}&location=${encodeURIComponent(
        this.event.location
      )}&body=${encodeURIComponent(this.event.description)}`

      window.open(link, '_blank')
    },

    generateMicrosoftTeamsCalendarLink() {
      const link = `https://teams.microsoft.com/l/meeting/new?subject=${encodeURIComponent(
        this.event.title
      )}&startDateTime=${this.formatDateForUrl(
        this.event.start
      )}&endDateTime=${this.formatDateForUrl(
        this.event.end
      )}&location=${encodeURIComponent(
        this.event.location
      )}&content=${encodeURIComponent(this.event.description)}`

      window.open(link, '_blank')
    },

    generateOutlookCalendarLink() {
      const link = `https://outlook.live.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(
        this.event.title
      )}&startdt=${this.formatDateForUrl(
        this.event.start
      )}&enddt=${this.formatDateForUrl(
        this.event.end
      )}&location=${encodeURIComponent(
        this.event.location
      )}&body=${encodeURIComponent(this.event.description)}`

      window.open(link, '_blank')
    },

    generateIcsFile() {
      const icsFileContent = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//${this.$t('cable_cars_switzerland')}//${this.event.title}//${
        this.$i18n.locale
      }
BEGIN:VEVENT
UID:${this.event.title}
DTSTAMP:${this.event.start.toISOString().replace(/-|:|\.\d+/g, '')}
DTSTART:${this.event.start.toISOString().replace(/-|:|\.\d+/g, '')}
DTEND:${this.event.end.toISOString().replace(/-|:|\.\d+/g, '')}
SUMMARY:${this.event.title}
DESCRIPTION:${this.event.description}
LOCATION:${this.event.location}
END:VEVENT
END:VCALENDAR`

      const downloadLink = document.createElement('a')
      downloadLink.href =
        'data:text/calendar;charset=utf-8,' + encodeURIComponent(icsFileContent)
      downloadLink.download = `${this.event.title.replace(/\s+/g, '-')}.ics`

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    },
  },
}
</script>
