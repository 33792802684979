<template>
  <div class="-mb-7 pb-7">
    <!-- NavItem  -->
    <button
      :class="{ 'text-blue-800': containsActiveLink }"
      class="nav-link list-title flex w-full items-center justify-between"
      @click="openSubmenu"
    >
      <span
        data-flip-id="header-submenu"
        class="header-title flex-1 text-left text-lg font-medium"
      >
        {{ navItemLevelTop.page.title }}
      </span>

      <CaretRightIcon class="ml-3 h-4 text-blue-800" />
    </button>
    <!-- END NavItem  -->

    <!-- Submenu -->
    <Transition name="fade-slow">
      <div
        v-show="isOpen"
        class="submenu fixed inset-x-0 bottom-0 top-[5rem] z-10 overflow-y-auto bg-white pt-10"
      >
        <!-- Submenu Header -->
        <div class="relative z-10 bg-white pl-14 pr-10">
          <button
            class="-ml-4 flex items-center"
            :aria-label="$t('back')"
            @click="closeSubmenu"
          >
            <div class="arrow-back shrink-0">
              <CaretRightIcon
                class="w-2.5 rotate-180 text-blue-800 transition-transform duration-300"
              />
            </div>
            <div class="ml-4 whitespace-nowrap text-2xl font-medium">
              {{ navItemLevelTop.page.title }}
            </div>
          </button>
        </div>
        <!-- END Submenu Header -->

        <!-- Submenu Links -->
        <div
          class="fixed inset-x-0 bottom-0 top-[7.75rem] overflow-y-auto pb-10"
        >
          <div
            v-for="(navItemLevel1, index) in navItemLevelTop.children"
            :key="navItemLevel1.page.id"
            class="relative py-7 pl-[4.2rem] pr-10"
            :class="[
              { 'bg-bg-blue-50/60': navItemLevel1.page.bg_blue_enabled },
              { 'mt-13': index === 0 && navItemLevel1.page.bg_blue_enabled },
              { 'mt-6': index === 0 && !navItemLevel1.page.bg_blue_enabled },
            ]"
          >
            <NuxtLink
              :to="navItemLevel1.page.url"
              class="nav-item-level-1 list-title text-lg font-medium leading-tight"
            >
              {{ navItemLevel1.page.title }}
            </NuxtLink>

            <ul v-if="navItemLevel1.children.length > 0" class="mt-5 space-y-5">
              <template v-for="navItemLevel2 in navItemLevel1.children">
                <li
                  v-if="navItemLevel2.page.url"
                  :key="navItemLevel2.page.id"
                  class="nav-item-level-2 text-md font-normal leading-tight"
                >
                  <NuxtLink
                    v-if="!navItemLevel2.page.show_as_button"
                    :to="navItemLevel2.page.url"
                  >
                    {{ navItemLevel2.page.title }}
                  </NuxtLink>
                  <AppButton
                    v-else
                    :to="navItemLevel2.page.url"
                    class="mt-2 !text-white"
                  >
                    {{ navItemLevel2.page.title }}
                  </AppButton>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <!-- END Submenu Links -->
      </div>
    </Transition>
    <!-- END Submenu -->
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
      containsActiveLink: false,
      tl: null,
      state: null,
    }
  },

  computed: {
    navItemLevelTop() {
      return this.data
    },
  },

  watch: {
    $route() {
      if (!this.isOpen) return

      this.closeSubmenu()

      this.checkIfContainsActiveLink()
    },
  },

  mounted() {
    this.checkIfContainsActiveLink()
  },

  methods: {
    openSubmenu() {
      this.isOpen = true
    },

    async closeSubmenu() {
      this.isOpen = false
    },

    checkIfContainsActiveLink() {
      this.$nextTick(() => {
        this.containsActiveLink = !!this.$el.querySelector(
          '.router-link-active'
        )

        if (this.containsActiveLink) {
          this.openSubmenu()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
// .arrow-back {
//   @apply opacity-0 translate-x-4;
// }

// .nav-item-level-1,
// .nav-item-level-2 {
//   @apply opacity-0 translate-y-8;
// }

// .nav-item-level-2 {
//   .nuxt-link-exact-active {
//     @apply text-blue-800;
//   }
// }
</style>
