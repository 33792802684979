<template>
  <ContentSection>
    <Container indent="center">
      <h2
        v-if="data.heading"
        class="default-component-title mb-8 sm:mb-10"
        v-html="$replaceHtmlTags(data.heading)"
      />
      <AnimatedContainer>
        <ul class="grid gap-4">
          <!-- ACCORDION -->
          <li
            v-for="(accordion, index) in data.accordion_group"
            :key="index"
            class="fade-in"
          >
            <Accordion
              class="rounded-sm transition-colors hover:bg-[#F7FBFE]"
              :duration-open="300"
              :is-open="index === openAccordionIndex"
              @open="openAccordionIndex = index"
              @close="
                index === openAccordionIndex
                  ? (openAccordionIndex = null)
                  : null
              "
            >
              <template #summary>
                <div
                  class="max-w-title py-4 pl-4 text-md font-medium sm:pr-10 sm:text-lg"
                >
                  {{ accordion.heading }}
                </div>
              </template>
              <template #content>
                <Bard
                  class="pb-4 pl-4 pr-16 pt-3 sm:pb-4 sm:pt-2 sm:text-md"
                  :data="accordion.content"
                />
              </template>
            </Accordion>
          </li>
        </ul>
      </AnimatedContainer>
    </Container>
  </ContentSection>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      openAccordionIndex: null,
    }
  },
}
</script>
