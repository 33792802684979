<template>
  <li
    v-if="alternates.length > 1 || route.path.includes('auth')"
    class="relative flex items-start"
  >
    <select
      class="flex appearance-none bg-transparent pr-4 text-sm uppercase outline-none ring-offset-0"
      :aria-label="$t('switch_language')"
      :value="currentLocale"
      @input="switchLanguage"
    >
      <template v-if="route.path.includes('auth')">
        <option v-for="locale in locales" :key="locale.code" class="uppercase">
          {{ locale.code }}
        </option>
      </template>

      <option
        v-for="{ locale, url } in alternates"
        v-else
        :key="url"
        class="uppercase"
      >
        {{ locale }}
      </option>
    </select>
    <CaretRightIcon
      stroke-width="2"
      class="absolute right-1 top-[0.325em] w-1.5 rotate-90"
    />
  </li>
</template>

<script setup>
const route = useRoute()
const { locale: currentLocale, locales } = useI18n()
const alternates = useState('alternates', () => [])
const switchLocalePath = useSwitchLocalePath()

function switchLanguage(event) {
  const locale = event.target.value

  // Do not use alternates if its a auth page
  const isAuthPage = route.path.startsWith('/auth')

  if (
    Array.isArray(alternates.value) &&
    alternates.value.length &&
    !isAuthPage
  ) {
    navigateTo(alternates.value.find((a) => a.locale === locale).url)
  } else {
    navigateTo(switchLocalePath(locale))
  }
}
</script>
