<template>
  <ContentSection>
    <AnimatedContainer>
      <Container ref="el" class="scrollbar-none lg:overflow-x-auto">
        <div class="scroll-container flex flex-col lg:flex-row">
          <div class="max-w-[36.25rem] shrink-0 lg:pr-40">
            <h2
              class="text-3xl font-medium lg:text-6xl"
              v-html="$removeHtmlTags(data.heading)"
            />

            <div class="mt-6 text-md lg:mt-8" v-html="data.text" />

            <button
              class="group mt-9 flex items-center rounded-sm font-medium text-blue-800 lg:mt-16"
              :aria-label="$t('accessibility.scroll_to_slider_content')"
            >
              {{ data.scroll_button_text }}

              <ArrowGrowIcon
                class="ml-3 w-5 transition-transform duration-300 group-hover:translate-x-2 sm:w-6"
              />
            </button>
          </div>

          <div
            class="scrollbar-none -mx-6 mt-16 flex overflow-x-auto px-6 sm:mx-[-5.625rem] sm:px-[5.625rem] lg:mt-0 lg:h-auto lg:shrink-0 lg:overflow-visible"
          >
            <ul
              class="grid h-[28.569375rem] max-w-[57.9rem] shrink-0 grid-cols-4 grid-rows-2 gap-3 lg:h-auto lg:max-w-[87.5rem] lg:gap-6"
            >
              <li
                v-for="(entry, index) in data.pages"
                :key="index"
                :class="getGridSpanClasses(index)"
                class="slide-in-left"
              >
                <EntryCard
                  class="aspect-square h-full w-full lg:h-auto"
                  small
                  :index="index"
                  :data="entry"
                />
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </AnimatedContainer>
  </ContentSection>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mm: null,
    }
  },

  async mounted() {
    await this.$nextTick()

    this.$gsap.delayedCall(0.5, () => {
      this.initScrollTrigger()
    })
  },

  beforeUnmount() {
    this.mm.kill()
  },

  methods: {
    initScrollTrigger() {
      this.mm = this.$gsap.matchMedia()
      const startOffset = 120
      const scrollXOffset = 90
      const scrollContainer = this.$el.querySelector('.scroll-container')

      this.mm.add(
        '(min-width: 1024px)',
        () => {
          this.$gsap.to(scrollContainer, {
            duration: 0.1,
            x:
              -(
                scrollContainer.scrollWidth -
                document.documentElement.clientWidth +
                scrollXOffset
              ) + 'px',
            ease: 'none',
            scrollTrigger: {
              trigger: scrollContainer,
              pin: true,
              scrub: 0.2,
              anticipatePin: 1,
              start: `-${startOffset}px top`, // offset is needed because of sticky navigation
              end: '+=' + scrollContainer.scrollWidth - 75,
              ease: 'none',
              invalidateOnRefresh: true,
              markers: false,
            },
          })
        },
        this.$el
      )
    },

    getGridSpanClasses(index) {
      if (index === 1) return 'col-span-1 row-start-2'
      if (index === 2) return 'col-span-2 row-span-2'
      if (index === 4) return 'col-start-4 row-start-2'

      return 'col-span-1 row-span-1'
    },
  },
}
</script>
