export const useTarget = (to: string, target: string | null) => {
  if (!to) return null

  // if target is null or auto, determine auto if it's an external link
  if (target === null || target === 'auto') {
    return isExternalUrl(to) ? '_blank' : ''
  }

  // switch
  if (target === 'self') return '_self'
  if (target === 'blank') return '_blank'

  // default
  return isExternalUrl(to) ? '_blank' : ''
}
