import { defineNuxtPlugin } from '#app'
import VueFathom from '@ubclaunchpad/vue-fathom'

export default defineNuxtPlugin((nuxtApp) => {
  const siteID = nuxtApp.$config.public.FATHOM_SITE_ID

  nuxtApp.vueApp.use(VueFathom, {
    siteID,
    settings: {
      spa: 'history',
    },
  })
})
