<template>
  <ContentSection>
    <Container>
      <!-- TITLE -->
      <h2
        v-if="data.heading"
        class="default-component-title mb-8 sm:mb-10"
        v-html="$replaceHtmlTags(data.heading)"
      />

      <AnimatedContainer>
        <!-- GRID -->
        <ul
          class="grid auto-rows-fr grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4"
        >
          <li
            v-for="(entry, index) in data.entries"
            :key="entry.slug"
            class="slide-in-left aspect-square"
            :class="{
              'md:col-span-2 md:row-span-2': index === 0,
              'row-span-1 md:col-span-2 md:!aspect-auto':
                index === data.entries.length - 1 && data.entries.length < 5,
            }"
          >
            <EntryCard
              class="h-full w-full"
              :enable-big-title="index === 0"
              :data="entry"
            />
          </li>
        </ul>
      </AnimatedContainer>
    </Container>
  </ContentSection>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
