<template>
  <div>
    <!-- INFORMATION GIVES -->
    <div v-if="!page.identical_to_applicant_person">
      <h2 class="mb-[0.625rem]">{{ $t('information_gives') }}</h2>
      <p class="mb-2 font-medium">
        {{ page.information_gives_first_name }}
        {{ page.information_gives_last_name }}
      </p>
      <a
        v-if="page.information_gives_email"
        class="mb-2 block hyphens-auto text-blue-800 transition-colors hover:text-blue-dark"
        :href="`mailto:${page.information_gives_email}`"
      >
        {{ page.information_gives_email }}
      </a>
      <a
        v-if="page.information_gives_phone"
        class="block text-blue-800 transition-colors hover:text-blue-dark"
        :href="`tel:${page.information_gives_phone}`"
      >
        {{ page.information_gives_phone }}
      </a>
    </div>

    <!-- APPLICATION TO -->
    <div>
      <h2 class="mb-[0.625rem]">{{ $t('application_to') }}</h2>
      <p class="mb-2 font-medium">
        {{ page.application_to_first_name }}
        {{ page.application_to_last_name }}
      </p>
      <a
        v-if="page.application_to_email"
        class="mb-2 block hyphens-auto text-blue-800 transition-colors hover:text-blue-dark"
        :href="`mailto:${page.application_to_email}`"
      >
        {{ page.application_to_email }}
      </a>
      <a
        v-if="page.application_to_phone"
        class="block text-blue-800 transition-colors hover:text-blue-dark"
        :href="`tel:${page.application_to_phone}`"
      >
        {{ page.application_to_phone }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
}
</script>
