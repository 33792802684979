export default defineNuxtPlugin(async () => {
  const { user, setUser } = useAuthStore()

  const fetchUser = async (force: boolean = false) => {
    if (!user || force) {
      try {
        const data = await useApi('/me')
        setUser(data.value)
      } catch (error) {
        console.error('Error fetching user:', error)
      }
    } else {
      console.log('skip fetch')
    }
  }
  inject('fetchUser', fetchUser)

  // Fetch the user data on client side
  // We don't want user data in the ISR cache, therefore we only fetch it on client side
  await fetchUser()
})
