<template>
  <Container class="fixed inset-0 bg-white pt-20">
    <div class="scrollbar-none h-full overflow-y-auto">
      <div class="mb-36 flex h-full flex-col pt-8">
        <ul v-if="navItems.length > 0" class="space-y-3">
          <li
            v-for="({ page, children }, index) in navItems"
            :key="index"
            class="nav-links"
          >
            <!-- Can either be a regular link or a dropdown -->
            <Component
              :is="
                children.length > 0
                  ? 'MobileNavItemWithSubmenu'
                  : 'MobileNavItem'
              "
              :data="{ page, children }"
            />
          </li>
        </ul>

        <ul class="mt-6 space-y-4">
          <li>
            <NuxtLink :to="localePath('/search')" class="flex text-md">
              <SearchIcon class="mr-4 w-4 text-blue-800" />
              <span class="text-blue-dark-900"> {{ $t('search.search') }}</span>
            </NuxtLink>
          </li>

          <li>
            <NuxtLink :to="localePath('/login')" class="flex text-md">
              <UserIcon class="mr-3 w-5 text-blue-800" />
              <span class="text-blue-dark-900">
                {{ $t('sign_in') }}
              </span>
            </NuxtLink>
          </li>
        </ul>

        <ul v-if="meta.length > 0" class="mt-7 space-y-4">
          <li v-for="{ page } in meta" :key="page.id">
            <NuxtLink
              class="inline-block uppercase tracking-wide text-blue-dark-900/80"
              :to="page.url"
            >
              {{ page.title }}
            </NuxtLink>
          </li>
        </ul>

        <ClientOnly>
          <LangSwitcherMobile class="mt-5" />
        </ClientOnly>
      </div>
    </div>
  </Container>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useNavigationStore } from '~/stores/navigation'

export default {
  props: {
    navItems: {
      type: Array,
      default: () => [],
    },

    meta: {
      type: Array,
      default: () => [],
    },

    navItemsRight: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    const { mobileMenuIsOpen } = storeToRefs(useNavigationStore())

    return {
      mobileMenuIsOpen,
    }
  },

  data() {
    return {
      tl: null,
    }
  },

  async mounted() {
    await this.$nextTick()

    this.$gsap.delayedCall(0.4, this.initAnimation)
  },

  methods: {
    initAnimation() {
      if (!document.querySelector('.nav-link.list-title')) return

      this.tl = this.$gsap.timeline().to('.nav-link.list-title', {
        y: 0,
        opacity: 1,
        duration: 1,
        ease: 'power4',
        stagger: 0.11,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.nav-links {
  .router-link-exact-active {
    // @apply underline underline-offset-5;
  }
}

.locale.router-link-active {
  // @apply underline underline-offset-5;
}
</style>

<style lang="scss">
.nav-link.list-title {
  @apply opacity-0 translate-y-8;
}
</style>
