<template>
  <div :data-has-bg-img="hasBackgroundImage" class="relative" :class="colSpan">
    <div class="relative flex items-center">
      <Field
        :id="handle || field.handle"
        as="select"
        :value="value"
        :name="handle || field.handle"
        :label="label || field.label || field.display"
        :placeholder="handle || field.handle"
        :disabled="disabled"
        :rules="rulesAsString"
        :class="
          error
            ? 'border-red has-bg-img:pb-9 focus:border-transparent'
            : 'border-transparent'
        "
        class="peer block w-full appearance-none rounded-[0.0625rem] border bg-blue-100/20 px-6 pb-[0.8rem] pt-[1.27rem] text-md text-blue-dark-700 outline-none transition placeholder:text-transparent focus:outline-none focus:ring-2 has-bg-img:bg-white"
      >
        <option
          v-for="(option, index) in computedOptions"
          :key="index"
          :value="option.value"
          :selected="option.value === value"
        >
          {{ option.label }}
        </option>
      </Field>

      <label
        :for="handle || field.handle"
        class="absolute left-6 top-1.5 text-xs text-blue-dark-700/60 transition-all first-letter:uppercase peer-placeholder-shown:top-4 peer-placeholder-shown:text-md peer-placeholder-shown:text-blue-dark-700/60 peer-focus:top-1.5 peer-focus:text-xs peer-focus:text-blue-dark-700/60"
      >
        <!-- eslint-disable-next-line -->
        {{ label || field.label || field.display }}  {{ !isRequired ? $t('optional') : '' }}
      </label>

      <CaretRightIcon
        stroke-width="2"
        class="absolute right-5 top-1/2 w-[0.375rem] -translate-y-1/2 rotate-90 text-blue-800"
      />
    </div>

    <Transition name="fade">
      <p
        v-if="error"
        class="pt-1 text-sm text-red has-bg-img:absolute has-bg-img:bottom-[0.625rem] has-bg-img:line-clamp-1 has-bg-img:px-5"
      >
        {{ error }}
      </p>
    </Transition>
  </div>
</template>

<script>
import { Field } from 'vee-validate'

export default {
  components: {
    Field,
  },

  inject: {
    hasBackgroundImage: {
      default: false,
    },
  },

  props: {
    handle: {
      type: String,
      default: null,
    },

    field: {
      type: Object,
      default: null,
    },

    value: {
      type: null,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    rules: {
      type: [Array, String],
      default: '',
    },

    error: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    width: {
      type: [Number, String],
      default: null,
    },

    options: {
      type: [Array, Object],
      default: () => [],
    },
  },

  computed: {
    rulesAsString() {
      if (Array.isArray(this.rules)) {
        return this.rules.join('|')
      }
      return this.rules
    },

    isRequired() {
      return this.rulesAsString.includes('required')
    },

    colSpan() {
      const width = parseInt(this.width) || this.field.width || 100

      switch (width) {
        case 25:
          return 'md:col-span-6 lg:col-span-3 col-span-12'
        case 33:
          return 'md:col-span-6 lg:col-span-4 col-span-12'
        case 50:
          return 'md:col-span-6 lg:col-span-6 col-span-12'
        case 66:
          return 'md:col-span-6 lg:col-span-8 col-span-12'
        default:
          return 'md:col-span-6 lg:col-span-12 col-span-12'
      }
    },

    computedOptions() {
      if (this.field?.config?.options) {
        return Object.entries(this.field.config.options).map(([value]) => ({
          value,
          label: this.$t(value),
        }))
      }

      if (this.options?.length > 0) return this.options

      return this.field?.options
    },
  },
}
</script>
