import { defineRule, configure } from 'vee-validate'
import { required, email, numeric, max, url, mimes } from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import de from '@vee-validate/i18n/dist/locale/de.json'
import fr from '@vee-validate/i18n/dist/locale/fr.json'

export default defineNuxtPlugin((app) => {
  defineRule('required', required)
  defineRule('email', email)
  defineRule('numeric', numeric)
  defineRule('integer', numeric)
  defineRule('max', max)
  defineRule('url', url)
  defineRule('mimes', mimes)

  defineRule('phone', (value) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true
    }
    const regex = /^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/
    return regex.test(value)
  })

  // to handle the nullable fields rules from statamic
  defineRule('nullable', () => {
    return true
  })

  // Custom URL validation rule allowing www.blabla.com without http(s)://
  defineRule('custom_url', (value) => {
    const urlPattern =
      /^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*\.[a-z]{2,}([^\s]*)?$/i
    if (!value || urlPattern.test(value)) {
      return true
    }
    return 'This field must be a valid URL'
  })

  de.messages.custom_url = '{field} ist keine gültige URL'
  fr.messages.custom_url = '{field} doit contenir une URL valide'

  configure({
    // controls if `blur` events should trigger validation with `handleChange` handler
    validateOnBlur: true,
    // controls if `change` events should trigger validation with `handleChange` handler
    validateOnChange: true,
    // controls if `input` events should trigger validation with `handleChange` handler
    validateOnInput: true,
    // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    validateOnModelUpdate: true,

    generateMessage: localize({
      de, // Including the de translations
      fr, // Including the fr translations
    }),
  })

  setLocale(app.$i18n.locale.value)

  // set correct locale on language switched
  app.$i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    setLocale(newLocale)
  }
})
