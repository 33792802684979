<template>
  <div
    class="group relative flex flex-col justify-end overflow-hidden"
    :class="{
      'bg-gradient-to-tr from-blue-dark-800 to-blue-dark-600': !imageOrVideo,
    }"
    @mouseover="hover(true)"
    @mouseleave="hover(false)"
  >
    <div class="absolute inset-0 -z-10 overflow-hidden">
      <!-- IMAGE OR VIDEO -->
      <AppImage
        v-if="imageOrVideo.image"
        :src="imageOrVideo.image"
        :alt="
          $i18n.locale === 'fr'
            ? imageOrVideo.image.alt_fr
            : imageOrVideo.image.alt
        "
        loading="lazy"
        class="h-full w-full object-cover transition-transform duration-300 ease-out group-hover:scale-105"
        :style="`object-position: ${imageOrVideo.image.focus_css};`"
      />

      <VideoPlayer
        v-else-if="imageOrVideo.video"
        ref="videoPlayer"
        play-on-hover
        :cover-image="imageOrVideo.cover_image"
        :src="imageOrVideo.video"
        class="h-full w-full scale-[1.25]"
      />
    </div>

    <!-- TITLE -->
    <NuxtLink
      :to="data.url"
      class="ring-blue-800 ring-offset-0 before:absolute before:inset-0"
      :aria-label="`${$t('accessibility.link_to')} ${data.heading}`"
    >
      <div
        class="relative flex justify-between p-6 font-bold text-white"
        :class="{ 'md:p-8': enableBigTitle }"
      >
        <h3
          class="relative z-10 mr-[10%] line-clamp-5 max-w-title"
          :class="
            enableBigTitle
              ? 'text-2xl leading-8 md:text-5xl'
              : 'text-xl md:text-2xl leading-7 md:!leading-[1.875rem]'
          "
          v-html="$replaceHtmlTags(data.heading)"
        />

        <ArrowGrowIcon class="z-10 mb-[0.5em] w-5 shrink-0 self-end sm:w-6" />
        <!-- GRADIENT -->
        <div
          class="absolute inset-x-0 -top-10 bottom-0 bg-gradient-to-t from-[#012D43]/60 to-transparent"
        />
      </div>
    </NuxtLink>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    enableBigTitle: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    imageOrVideo() {
      if (this.data.image || this.data.entry_image) {
        return { image: this.data.image || this.data.entry_image }
      }

      return this.data?.image_or_video?.[0] || false
    },
  },

  methods: {
    async hover(value) {
      if (!this.imageOrVideo.video) {
        return
      }

      if (value) {
        return this.$refs.videoPlayer.play()
      }

      this.$refs.videoPlayer.pause()
    },
  },
}
</script>
