import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["Courses","CoursesAndTerms","CourtDecisions","CourtDecisionsAndTerms","DownloadsArea","DownloadsAreaSpecific","Entry","EntryProtected","Events","Experts","ExpertsAndTerms","JobTerms","Jobs","JobsAndTerms","JobsComplete","NavigationsAndGlobals","News","NewsAndTerms","SustainabilityArticles","SustainabilityArticlesAndTerms","TeamMembers","TeamMembersAndTerms","DownloadsAreaFile","DownloadsAreaFolder","EntryCards","Expert","formFields","Alternates","Breadcrumbs","Asset","ImageOrVideo","PreviewCourse","PreviewCourtDecision","PreviewEvent","PreviewJob","PreviewNewsArticle","PreviewSustainabilityArticle","TeamMember","BlueprintContact","BlueprintCourse","BlueprintCourtDecision","BlueprintEvent","BlueprintJob","BlueprintLogin","BlueprintNewsArticle","BlueprintPageEvent","BlueprintPageLevel1","BlueprintPageLevel2","BlueprintPageSimple","BlueprintProtectedEntry","BlueprintSection","BlueprintSustainabilityArticle","BlueprintUserRegistration","BlueprintPage","ReplicatorComponents"]}
export const GqlCourses = (...params) => useGql()('Courses', ...params)
export const GqlCoursesAndTerms = (...params) => useGql()('CoursesAndTerms', ...params)
export const GqlCourtDecisions = (...params) => useGql()('CourtDecisions', ...params)
export const GqlCourtDecisionsAndTerms = (...params) => useGql()('CourtDecisionsAndTerms', ...params)
export const GqlDownloadsArea = (...params) => useGql()('DownloadsArea', ...params)
export const GqlDownloadsAreaSpecific = (...params) => useGql()('DownloadsAreaSpecific', ...params)
export const GqlEntry = (...params) => useGql()('Entry', ...params)
export const GqlEntryProtected = (...params) => useGql()('EntryProtected', ...params)
export const GqlEvents = (...params) => useGql()('Events', ...params)
export const GqlExperts = (...params) => useGql()('Experts', ...params)
export const GqlExpertsAndTerms = (...params) => useGql()('ExpertsAndTerms', ...params)
export const GqlJobTerms = (...params) => useGql()('JobTerms', ...params)
export const GqlJobs = (...params) => useGql()('Jobs', ...params)
export const GqlJobsAndTerms = (...params) => useGql()('JobsAndTerms', ...params)
export const GqlJobsComplete = (...params) => useGql()('JobsComplete', ...params)
export const GqlNavigationsAndGlobals = (...params) => useGql()('NavigationsAndGlobals', ...params)
export const GqlNews = (...params) => useGql()('News', ...params)
export const GqlNewsAndTerms = (...params) => useGql()('NewsAndTerms', ...params)
export const GqlSustainabilityArticles = (...params) => useGql()('SustainabilityArticles', ...params)
export const GqlSustainabilityArticlesAndTerms = (...params) => useGql()('SustainabilityArticlesAndTerms', ...params)
export const GqlTeamMembers = (...params) => useGql()('TeamMembers', ...params)
export const GqlTeamMembersAndTerms = (...params) => useGql()('TeamMembersAndTerms', ...params)