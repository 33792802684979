import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Flip from 'gsap/Flip'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger, Flip, ScrollToPlugin)

gsap.config({
  nullTargetWarn: false,
})

export default defineNuxtPlugin(() => {
  return {
    provide: {
      gsap,
      scrollTrigger: ScrollTrigger,
      flip: Flip,
    },
  }
})
