export const useGlobalsStore = defineStore('globals', {
  state: () => ({
    general: null,
    navs: [],
    component_newsletter_defaults: {},
  }),

  getters: {
    mainLinks: (state) => {
      return state.navs?.find((nav) => nav.handle === 'main')?.tree || []
    },

    metaLinks: (state) => {
      return state.navs?.find((nav) => nav.handle === 'meta')?.tree || []
    },

    footerLinks: (state) => {
      return state.navs?.find((nav) => nav.handle === 'footer')?.tree || []
    },
  },
})
