<template>
  <button
    aria-label="Mobile Menu"
    class="burger"
    :class="[{ open: mobileMenuIsOpen }, { 'has-scrolled': thresholdCrossed }]"
    @click="toggle"
  >
    <span class="icon" />
  </button>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useNavigationStore } from '~/stores/navigation'
export default {
  props: {
    thresholdCrossed: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { mobileMenuIsOpen } = storeToRefs(useNavigationStore())

    return {
      mobileMenuIsOpen,
    }
  },

  watch: {
    mobileMenuIsOpen(isOpen) {
      if (isOpen) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    },

    '$route.path'() {
      this.close()
    },
  },

  mounted() {
    this.registerResizeEvent()
  },

  methods: {
    toggle() {
      this.mobileMenuIsOpen = !this.mobileMenuIsOpen

      if (this.mobileMenuIsOpen) {
        return this.$emit('opened')
      }

      this.$emit('closed')
    },

    close() {
      this.mobileMenuIsOpen = false
      this.$emit('closed')
    },

    registerResizeEvent() {
      this.$nextTick(() => {
        window.addEventListener(
          'resize',
          useThrottleFn(() => {
            if (window.innerWidth >= 1024) {
              this.close()
            }
          }, 500)
        )
      })
    },
  },
}
</script>

<style scoped lang="scss">
@mixin btn($right, $top) {
  width: 30px;
  height: 40px;
  position: relative;
  transition-duration: 0.3s;

  @screen sm {
    width: 40px;
  }
}

@mixin icon($height, $width) {
  transition-duration: 0.3s;
  position: absolute;
  height: $height;
  width: $width;
  right: 0;
  border-radius: 999px;
  @apply bg-white;
}

@mixin icon-before($height, $width, $top) {
  border-radius: 999px;
  transition-duration: 0.3s;
  position: absolute;
  width: $width;
  height: $height;
  content: '';
  top: $top;
  right: 0;
  transform: translate(0, 100%);
  @apply bg-white;
}

@mixin icon-after($height, $width, $top) {
  border-radius: 999px;
  transition-duration: 0.3s;
  position: absolute;
  width: $width;
  height: $height;
  content: '';
  top: $top;
  right: 0;
  transform: translate(0, -100%);
  @apply bg-white;
}

.burger {
  @include btn(120px, 100px);
  @apply z-top;

  .icon {
    @include icon(2px, 27px);

    &:before {
      @include icon-before(2px, 27px, -11px);
    }

    &:after {
      @include icon-after(2px, 27px, 11px);
    }
  }

  @screen sm {
    .icon {
      @include icon(3px, 35px);

      &:before {
        @include icon-before(3px, 35px, -15px);
      }

      &:after {
        @include icon-after(3px, 35px, 15px);
      }
    }
  }

  &.open {
    .icon {
      transition-duration: 0.5s;

      &:before {
        @apply bg-blue-800;
        transform: rotateZ(45deg) translate(36%, 5px);

        @screen sm {
          transform: rotateZ(45deg) translate(37%, 6px);
        }
      }

      &:after {
        @apply bg-blue-800;
        transform: rotateZ(-45deg) translate(36%, -6px);

        @screen sm {
          transform: rotateZ(-45deg) translate(42%, -9px);
        }
      }
    }
  }
  &:hover {
    cursor: pointer;
  }

  &.has-scrolled {
    .icon,
    .icon:before,
    .icon:after {
      @apply bg-blue-800;
    }
  }

  &.open.has-scrolled {
    .icon {
      @apply bg-transparent;
    }
  }
}
</style>
