<template>
  <button
    class="group -m-1 flex items-center gap-3 p-1 text-base font-medium text-blue-800 transition-colors hover:text-blue-dark"
    :aria-label="$t('accessibility.scroll_to_content')"
    @click="scrollTo()"
  >
    <slot />
    <!-- Icon will be replaced with a arrow icon with growing transform -->
    <ArrowGrowIcon class="mt-2 w-6 rotate-90" />
  </button>
</template>

<script>
export default {
  methods: {
    scrollTo() {
      const selector = '[scroll-to-index="0"]'
      const element = document.querySelector(selector)

      if (!element) {
        return
      }

      const headerOffset = 250
      const elementPosition = element.offsetTop
      const offsetPosition = elementPosition - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    },
  },
}
</script>
