<template>
  <ul class="flex space-x-3">
    <li v-for="{ locale } in alternates" :key="locale" class="uppercase">
      <button
        :class="{ '!text-blue-800': locale === currentLocale }"
        class="uppercase tracking-wide text-blue-dark-900/40"
        :aria-label="`${$t('switch_language')} ${locale}`"
        @click="switchLanguage(locale)"
      >
        {{ locale }}
      </button>
    </li>
  </ul>
</template>

<script setup>
const route = useRoute()
const { locale: currentLocale } = useI18n()
const alternates = useState('alternates', () => [])
const switchLocalePath = useSwitchLocalePath()

function switchLanguage(locale) {
  // Do not use alternates if its a auth page
  const isAuthPage = route.path.startsWith('/auth')

  if (
    Array.isArray(alternates.value) &&
    alternates.value.length &&
    !isAuthPage
  ) {
    navigateTo(alternates.value.find((a) => a.locale === locale).url)
  } else {
    navigateTo(switchLocalePath(locale))
  }
}
</script>
