export const replaceHtmlTags = (html: string) => {
  if (!html) return null

  // replace <em> with <ColorFullText>
  html = html.replace(/<\s*\/?\s*em\s*>/g, function (match) {
    if (match === '<em>') {
      return '<ColorFulText>'
    } else {
      return '</ColorFulText>'
    }
  })
  // replace <p> with '' and <br>
  return html.replace(/<\/p[^>]*><p[^>]*>/g, '<br>').replace(/<\/?p[^>]*>/g, '')
}
