<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    viewBox="0 0 41.188 66.572"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.12"
        y1="0.945"
        x2="0.878"
        y2="0.121"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#0091d2" />
        <stop offset="1" stop-color="#37a7df" />
      </linearGradient>
    </defs>
    <path
      id="Path_299"
      data-name="Path 299"
      d="M8.768,66.548l25.668-.025a6.759,6.759,0,0,0,6.753-6.77c0-.065-.084-18.328-.084-18.328A6.755,6.755,0,0,0,34.348,34.7H29.8l-1.281-.013a2.42,2.42,0,1,0,.006,4.839l.663.011h5.145a1.921,1.921,0,0,1,1.926,1.91c0,.068.085,18.328.085,18.328a1.916,1.916,0,0,1-1.913,1.912v-.022c-.263.022-.634.023-1.153.023L7.6,61.712H7.594A1.915,1.915,0,0,1,5.677,59.8L5.635,41.47a1.917,1.917,0,0,1,1.916-1.92v.023c.254-.023.607-.023,1.095-.024l5.911-.008a6.776,6.776,0,0,0,6.756-6.769h.048c-.049-.462-.05-1.266-.052-2.663l-.018-14.74L39.834,4.484a2.4,2.4,0,0,0,1.148-2.06A2.433,2.433,0,0,0,37.349.316l-.314.177L1.134,21.519A2.411,2.411,0,0,0,0,23.575a2.438,2.438,0,0,0,3.831,2l12.6-7.381.034,14.588A1.924,1.924,0,0,1,14.547,34.7V34.68c-.254.023-.607.023-1.1.024l-5.909.007A6.76,6.76,0,0,0,.787,41.485H.735c.054.485.055,1.343.059,2.865L.826,59.815A6.761,6.761,0,0,0,7.6,66.549h.019v.022c.264-.022.635-.023,1.154-.023"
      transform="translate(0 0.001)"
      fill="url(#linear-gradient)"
    />
  </svg>
</template>
x
