<template>
  <div
    class="top-navigation-desktop h-min bg-gradient-to-tr from-[#F7FBFE] to-[#EFF6FA] px-container-wide py-8 sm:px-container-desktop-wide sm:py-16 xl:sticky xl:px-8 xl:py-10"
  >
    <div class="grid grid-cols-2 gap-5 sm:gap-7 xl:grid-cols-1">
      <!-- PRICE MEMBERS -->
      <div v-if="page.price_members" class="flex flex-col justify-between">
        <h2 class="mb-3 opacity-60">{{ $t('price_for_members') }}</h2>
        <p class="font-medium">{{ page.price_members }}</p>
      </div>

      <!-- PRICE NON MEMBERS -->
      <div v-if="page.price_non_members" class="flex flex-col justify-between">
        <h2 class="mb-2 opacity-60 xl:mb-3">
          {{ $t('price_for_non_members') }}
        </h2>
        <p class="font-medium">{{ page.price_non_members }}</p>
      </div>

      <!-- LANGUAGES -->
      <div v-if="page.languages" class="flex flex-col justify-between">
        <h2 class="mb-2 opacity-60 xl:mb-3">{{ $t('languages') }}</h2>
        <p class="font-medium">
          {{ page.languages.map((language) => language.title)?.join(', ') }}
        </p>
      </div>

      <!-- COURSE DURATION -->
      <div v-if="page.duration" class="flex flex-col justify-between">
        <h2 class="mb-2 opacity-60 xl:mb-3">
          {{ $t('course_duration') }}
        </h2>
        <p class="font-medium">{{ page.duration }}</p>
      </div>
    </div>

    <!-- SCROLL TO COURSE DATES -->
    <button
      v-if="hasCourseDates"
      class="group mt-9 flex items-center gap-3 text-base font-medium text-blue-800 transition-colors hover:text-blue-dark xl:mt-14"
      @click="$emit('scroll-to-course-dates')"
    >
      <span>{{ $t('to_course_dates') }}</span>

      <ArrowGrowIcon class="w-6 rotate-90" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true,
    },

    hasCourseDates: {
      type: [null, Boolean],
      default: false,
    },
  },

  emits: ['scroll-to-course-dates'],
}
</script>
