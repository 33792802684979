export const useAuthStore = defineStore('auth', () => {
  const user = ref(null)
  const isLoading = ref(true)

  function setUser(value) {
    user.value = value
    isLoading.value = false
  }

  const isMember = computed(() => {
    if (!user.value) return false
    if (user.value?.super) return true
    return (
      user.value.roles?.includes('member') ||
      user.value.roles?.includes('regular_member')
    )
  })

  const isRegularMember = computed(() => {
    if (!user.value) return false
    if (user.value.super) return true
    return user.value && user.value.roles?.includes('regular_member')
  })

  return { user, setUser, isMember, isRegularMember, isLoading }
})
