<template>
  <div class="text-gray-800 flex min-h-screen w-full flex-col justify-between">
    <main class="flex-1 py-[30vh]">
      <div class="flex flex-col items-center text-center">
        <h1 class="text-7xl font-medium">{{ error.statusCode }}</h1>
        <h2>{{ error.message }}</h2>

        <div class="group mt-3 flex items-center text-blue-800">
          <ArrowGrowIcon class="mr-3 w-5 rotate-180" />
          <a href="/" :aria-label="$t('back_to_home')">
            {{ $t('back_to_home') }}
          </a>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { useStatamicPreviewMode } from "~/composables/useStatamicPreviewMode";

defineProps({
  error: {
    type: Object,
    required: true,
  },
})

const { enabled: previewModeEnabled } = useStatamicPreviewMode()
const route = useRoute()
const router = useRouter()
onMounted(() => {
  // this is workaround if vercel caches a 404 for a preview page but we are in live-preview
  if (previewModeEnabled.value) {
    previewModeEnabled.value = true
    router.push({path: route.path, query: route.query, force: true})
  }
})
</script>
