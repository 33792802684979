<template>
  <ul
    v-if="breadcrumbs"
    class="scrollbar-none scroll-container -mx-6 flex items-center overflow-x-auto px-6 py-1 text-sm transition-opacity"
    :class="ready ? 'opacity-100' : 'opacity-0'"
  >
    <li v-for="(crumb, index) in breadcrumbs" :key="crumb.url" class="flex">
      <p
        v-if="crumb.blueprint === 'section' || !crumb.url"
        class="shrink-0 whitespace-nowrap opacity-60"
      >
        {{ crumb.title }}
      </p>
      <NuxtLink
        v-else
        class="shrink-0 whitespace-nowrap ring-offset-0"
        :class="{
          'transition-colors hover:text-blue-dark': index > 0,
          'opacity-60': index < breadcrumbs.length - 1,
        }"
        :to="crumb.url"
      >
        {{ crumb.title }}
      </NuxtLink>
      <span
        v-if="index < breadcrumbs.length - 1"
        class="mx-1 shrink-0 opacity-60"
        >〡</span
      >
    </li>
  </ul>
</template>

<script>
export default {
  inject: {
    breadcrumbs: {
      default: () => [],
    },
  },

  data() {
    return {
      ready: false,
    }
  },

  async mounted() {
    await this.$nextTick()
    this.setScrollPosition()

    setTimeout(() => {
      this.ready = true
    }, 500)
  },

  methods: {
    isOverflowing() {
      return this.$el.scrollWidth >= this.$el.clientWidth
    },

    setScrollPosition() {
      if (this.isOverflowing()) {
        setTimeout(() => {
          this.$el.scrollLeft = this.$el.scrollWidth
        }, 500)
      }
    },
  },
}
</script>
