<template>
  <div class="relative">
    <!-- FACEBOOK LINK -->
    <a
      v-if="data.isFacebookPost"
      href="https://www.facebook.com/SiSeilbahnen/"
      class="absolute left-6 top-5 z-10 cursor-pointer py-1 text-sm opacity-60 transition-opacity hover:opacity-100"
      :class="data.isFacebookPost ? 'text-blue-dark-900' : 'text-white'"
      target="_blank"
      aria-label="Facebook SiSeilbahnen"
    >
      {{ $t('from_facebook') }}
    </a>

    <div
      class="group relative flex h-full min-h-[21rem] flex-col overflow-hidden rounded-sm sm:min-h-[27rem] 3xl:min-h-[32rem]"
      :class="[
        {
          'bg-gradient-to-tr from-blue-dark-800 to-blue-dark-600':
            !imageOrVideo.video && !imageOrVideo.image && !data.isFacebookPost,
          'bg-blue-10': data.isFacebookPost,
        },
        data.isFacebookPost ? 'text-blue-dark-900' : 'text-white',
      ]"
      @mouseover="hover(true)"
      @mouseleave="hover(false)"
    >
      <!-- NEWS TYPE -->
      <TypeTag
        class="mb-9 ml-6 mr-3 mt-3 place-self-end"
        :class="{ '!bg-white !text-blue-800': data.isFacebookPost }"
      >
        {{ data.isFacebookPost ? 'News International' : data.news_type.title }}
      </TypeTag>

      <div class="absolute inset-0 -z-10">
        <!-- IMAGE OR VIDEO -->
        <AppImage
          v-if="imageOrVideo.image"
          :src="imageOrVideo.image"
          :alt="
            $i18n.locale === 'fr'
              ? imageOrVideo.image.alt_fr
              : imageOrVideo.image.alt
          "
          loading="lazy"
          class="h-full w-full object-cover transition-transform duration-300 ease-out group-hover:scale-105"
          :style="`object-position: ${imageOrVideo.image.focus_css};`"
        />

        <VideoPlayer
          v-else-if="imageOrVideo.video"
          ref="videoPlayer"
          play-on-hover
          :src="imageOrVideo.video"
          :cover-image="imageOrVideo.cover_image"
          class="h-full w-full scale-[1.25]"
        />
        <!-- GRADIENT -->
        <div
          class="absolute inset-x-0 bottom-0 top-1/2 bg-gradient-to-t from-blue-dark-800/60 to-transparent"
        />
      </div>

      <!-- TEXT -->
      <p
        v-if="data.heading"
        class="mb-5 max-w-title px-7 text-xl font-bold leading-[1.875rem] sm:mb-3 sm:px-6 sm:text-xl sm:leading-7"
        :class="{ 'mt-auto': imageOrVideo }"
        v-html="$replaceHtmlTags(data.heading)"
      />

      <span
        v-if="!imageOrVideo && data.lead"
        class="mb-6 max-w-text overflow-hidden px-7 text-sm sm:px-6 sm:text-base"
        :class="
          data.isFacebookPost
            ? 'line-clamp-[10] max-h-[16.25rem]'
            : 'line-clamp-6 max-h-[9.75rem]'
        "
        v-html="data.lead"
      />

      <div
        class="flex items-center justify-between gap-5 px-7 pb-7 sm:px-6 sm:pb-6"
        :class="{ 'mt-auto': !imageOrVideo }"
      >
        <!-- DATE -->
        <time class="text-sm" :datetime="data.date">
          {{ date }}
        </time>
        <!-- LINK ARROW -->
        <NuxtLink
          v-if="data.url"
          :aria-label="`${$t('accessibility.link_to')} ${data.title}`"
          class="ring-blue-800 ring-offset-0 before:absolute before:inset-0"
          :target="$useTarget(data.url)"
          :to="data.url"
        >
          <ArrowGrowIcon
            class="w-5 transition-transform duration-300 group-hover:translate-x-2 sm:w-6"
          />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    date() {
      const date = new Date(this.data.date)
      return date.toLocaleDateString(this.$i18n.locale, {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      })
    },

    imageOrVideo() {
      const firstElement = this.data.image_or_video[0]

      if (
        firstElement &&
        (firstElement.type === 'image' || firstElement.type === 'video')
      ) {
        return firstElement
      }

      return false
    },
  },

  methods: {
    hover(value) {
      if (!this.imageOrVideo.video) {
        return
      }

      if (value) {
        return this.$refs.videoPlayer.play()
      }
      this.$refs.videoPlayer.pause()
    },
  },
}
</script>
