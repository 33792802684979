<template>
  <div class="text-main-blue flex justify-between">
    <!-- PREV BUTTON -->
    <div>
      <button
        class="x group flex h-8 w-8 items-center justify-center rounded-sm ring-blue-800 ring-offset-0 transition-colors hover:bg-white"
        :class="{ 'opacity-50': isBeginning }"
        :disabled="isBeginning"
        :aria-label="$t('previous_slide')"
        @click="$emit('prev')"
      >
        <div
          class="h-2"
          :class="{
            'transition-all group-hover:text-blue-800': !isBeginning,
          }"
        >
          <ArrowSimpleIcon class="h-full rotate-90" />
        </div>
      </button>
    </div>

    <!-- NEX BUTTON -->
    <div>
      <button
        :class="{ 'opacity-50': isEnd }"
        :disabled="isEnd"
        class="group flex h-8 w-8 items-center justify-center rounded-sm ring-blue-800 ring-offset-0 transition-colors hover:bg-white"
        :aria-label="$t('next_slide')"
        @click="$emit('next')"
      >
        <div
          class="h-2"
          :class="{
            'transition-all group-hover:text-blue-800': !isEnd,
          }"
        >
          <ArrowSimpleIcon class="h-full -rotate-90" />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
// use this component ether with ref or with props.
// usually use ref on the functions here. It is be less to configure on the parent (Gallery.vue)
// if you render this button multiple times e.g. on each slide use props ref will not work. (TeaserLargeSlider.vue)
// in this case you need to define the functions on the parent and pass them as props to this component

export default {
  props: {
    reachBeginning: {
      type: Boolean,
      default: true,
    },

    reachEnd: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isBeginning: true,
      isEnd: false,
    }
  },

  watch: {
    reachBeginning: {
      immediate: true,
      handler(newVal) {
        this.isBeginning = newVal
      },
    },

    reachEnd: {
      immediate: true,
      handler(newVal) {
        this.isEnd = newVal
      },
    },
  },

  methods: {
    async reachedBeginningOrEnd(beginningOrEnd) {
      // need to wait until fromEdge event has set its values
      await nextTick()

      if (beginningOrEnd === 'beginning') {
        this.isBeginning = true
      } else {
        this.isEnd = true
      }
    },

    fromEdge() {
      this.isBeginning = false
      this.isEnd = false
    },
  },
}
</script>
