export default defineNuxtPlugin(() => {
  return {
    provide: {
      replaceHtmlTags,
      removeHtmlTags,
      isExternalUrl,
      useTarget,
      useFormatUrlWithHttps,
    },
  }
})
