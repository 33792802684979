<template>
  <div
    class="-mb-7 pb-7"
    @mouseenter="open"
    @mouseleave="close"
    @keydown.esc="close"
  >
    <div class="flex flex-col items-center">
      <button
        :aria-label="navItemLevel1.page.title"
        class="cursor-default px-4 text-white ring-offset-0 transition-colors duration-300 nav-white:text-black"
        :class="[
          { '!text-blue-800': isOpen || containsActiveLink },
          { 'is-open': isOpen },
        ]"
        @keypress.enter="toggleDropdown"
        @mouseenter="enterNavItem"
        @mouseleave="leaveNavItem"
      >
        {{ navItemLevel1.page.title }}
      </button>
    </div>

    <!-- Dropdown -->
    <Transition
      @before-enter="beforeEnter"
      @enter="enter"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div
        v-show="isOpen"
        ref="dropdown"
        class="dropdown-menu fixed inset-x-0 top-[7rem] z-10 overflow-hidden border-t-2 border-blue-100/20 bg-white transition-[top] duration-200 ease-linear"
        :class="[
          { '!top-[5rem]': thresholdCrossed },
          containsLinksWithBlueBackground
            ? 'px-[5.625rem] xl:pl-0'
            : 'px-[5.625rem]',
        ]"
        @mouseenter="enterDropdown"
        @mouseleave="leaveDropdown"
      >
        <div
          class="content grid translate-y-4 grid-cols-4 opacity-0 nav:gap-x-4 xl:grid-cols-5 xl:gap-x-6"
        >
          <div
            v-for="(navItemLevel2, level2Index) in navItemLevel1.children"
            :key="navItemLevel2.page.id"
            class="relative py-5 font-normal leading-[1.4rem] sm:py-7 md:py-12"
            :class="
              conditionalSpacing(navItemLevel1, navItemLevel2, level2Index)
            "
          >
            <NuxtLink
              :to="navItemLevel2.page.url"
              :target="$useTarget(navItemLevel2.page.url)"
              class="hyphens-auto text-md font-medium leading-[1.1rem] transition-colors duration-300 hover:text-blue-800 xl:text-lg xl:leading-[1.4rem]"
            >
              {{ navItemLevel2.page.title }}
            </NuxtLink>

            <ul
              v-if="navItemLevel2.children.length > 0"
              class="mt-4 flex flex-col space-y-4"
            >
              <template v-for="navItemLevel3 in navItemLevel2.children">
                <li
                  v-if="navItemLevel3.page.url"
                  :key="navItemLevel3.page.id"
                  class="nav-item-level-3 pr-4 text-base leading-[1.4rem] transition-colors duration-300 hover:text-blue-400 xl:text-md xl:leading-[1.4rem]"
                >
                  <NuxtLink
                    v-if="!navItemLevel3.page.show_as_button"
                    :target="$useTarget(navItemLevel3.page.url)"
                    :to="navItemLevel3.page.url"
                    class="hyphens-auto"
                  >
                    {{ navItemLevel3.page.title }}
                  </NuxtLink>
                  <AppButton
                    v-else
                    :to="navItemLevel3.page.url"
                    class="mt-4 !text-white"
                  >
                    {{ navItemLevel3.page.title }}
                  </AppButton>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </Transition>

    <!-- END Dropdown -->
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    navDropdownIsOpen: {
      type: Boolean,
      required: true,
    },

    thresholdCrossed: {
      type: Boolean,
      default: null,
    },

    index: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      tl: null,
      isOpen: false,
      containsActiveLink: false,
      dropdownIsHovered: false,
      containsLinksWithBlueBackground: false,
      timer: null,
    }
  },

  computed: {
    navItemLevel1() {
      return this.data
    },
  },

  watch: {
    $route() {
      this.isOpen = false

      this.checkIfContainsActiveLink()
    },

    isOpen(value) {
      this.$emit('dropdown-toggled', value)
    },
  },

  mounted() {
    this.checkIfContainsActiveLink()
  },

  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen
    },

    open() {
      // emit open event with current dropdown
      this.$emit('dropdown-opened', this.index)

      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.isOpen = true
    },

    close() {
      if (this.dropdownIsHovered) {
        return
      }

      // start timeout
      this.timer = setTimeout(() => {
        const navItems = [...document.querySelectorAll('.nav-items li')]

        navItems.forEach((node) => {
          node.classList.remove('pointer-events-none')
        })

        this.isOpen = false
      }, 200)
    },

    shouldRenderLink(navItemLevel2) {
      return navItemLevel2.children.length === 0
    },

    checkIfContainsActiveLink() {
      this.$nextTick(() => {
        this.containsActiveLink = !!this.$el.querySelector(
          '.router-link-active'
        )
      })
    },

    enterDropdown() {
      this.dropdownIsHovered = true

      if (this.timer) {
        clearTimeout(this.timer)
      }
    },

    leaveDropdown() {
      this.dropdownIsHovered = false
      this.isOpen = false
      this.timer = null
      this.$emit('dropdown-closed')
    },

    // Transition Hooks:

    beforeEnter(el) {
      el.classList.add('pointer-events-none')
    },

    enter(el) {
      this.ctx = this.$gsap.context(() => {
        if (this.tl && this.tl.isActive()) {
          return
        }

        // actual animation
        this.tl = this.$gsap
          .timeline({
            defaults: {
              duration: 0.15,
            },
            onComplete: () => {
              el.classList.remove('pointer-events-none')
            },
          })
          .to('.dropdown-menu', {
            opacity: 1,
          })
          .to(
            '.content',
            {
              opacity: 1,
              y: 0,
            },
            0
          )
      }, this.$el)
    },

    leave(el, done) {
      this.tl.reverse()

      this.tl.eventCallback('onReverseComplete', done)
    },

    afterLeave() {
      this.ctx.revert()
    },

    conditionalSpacing(navItemLevel1, navItemLevel2, index) {
      if (navItemLevel2.page.bg_blue_enabled && index === 0) {
        this.containsLinksWithBlueBackground = true
        return 'xl:bg-blue-100/20 pr-10 xl:pr-16 xl:pl-container-desktop'
      }

      if (
        navItemLevel2.page.bg_blue_enabled &&
        navItemLevel1.children.length - 1 === index
      ) {
        return 'pl-[5.625rem] pr-container sm:pr-container-desktop'
      }

      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
  @apply opacity-0;

  .router-link-exact-active {
    @apply text-blue-800;
  }
}
</style>
