<template>
  <div
    class="sticky top-navigation-desktop h-min bg-gradient-to-tr from-blue-50/60 to-blue-10/60 px-8 py-10"
  >
    <JobMetaTiles is-white :page="page" class="space-y-4" />

    <JobContacts :page="page" class="my-8 space-y-8" />

    <AppButton
      v-if="page.link_to_online_application"
      :to="useFormatUrlWithHttps(page.link_to_online_application)"
    >
      {{ $t('to_online_application') }}
    </AppButton>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
}
</script>
