<template>
  <ContentSection>
    <Container class="sm:px-container-desktop-wide">
      <!-- TITLE -->
      <h2
        class="mb-8 max-w-title text-2xl font-medium sm:mb-10"
        v-html="$replaceHtmlTags(heading || $t('overview_course_dates'))"
      />

      <!-- COURSE DATES -->
      <AnimatedContainer>
        <div class="grid gap-4">
          <CourseDateCard
            v-for="course in courseDates"
            :key="course.id"
            :data="course"
            class="fade-in"
          />
        </div>
      </AnimatedContainer>
    </Container>
  </ContentSection>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: null,
    },

    courseDates: {
      type: Array,
      required: true,
    },
  },
}
</script>
