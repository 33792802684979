export const useNavigationStore = defineStore('navigation', () => {
  const isWhite = ref(false)
  const mobileMenuIsOpen = ref(false)
  const blackLogo = ref(false)
  const { $gsap } = useNuxtApp()
  const imgWidth = ref(0)
  const navItemsWidth = ref(0)
  const allowedResizeDistance = ref(0)
  const threshold = ref(60)
  const width = ref(0)

  function setIsWhite(value) {
    isWhite.value = value
  }

  function setBlackLogo(value) {
    blackLogo.value = value
  }

  function initWindowResizeWatcher() {
    setBlackLogo(false)
    setIsWhite(false)

    imgWidth.value = document.querySelector('img').clientWidth
    navItemsWidth.value = document.querySelector('.nav-items').clientWidth
    allowedResizeDistance.value = imgWidth.value - navItemsWidth.value
    width.value =
      window.innerWidth - allowedResizeDistance.value + threshold.value

    if (width.value < 1200) {
      width.value = 1250 + threshold.value
    }

    let mm = $gsap.matchMedia()

    mm.add(`(min-width: 1024px)`, () => {
      setBlackLogo(true)

      return () => setBlackLogo(false)
    })

    mm.add(`(min-width: 1120px)`, () => {
      setIsWhite(true)

      return () => setIsWhite(false)
    })

    mm.add(`(min-width: ${width.value}px)`, () => {
      setIsWhite(false)
      setBlackLogo(true)

      return () => {
        setIsWhite(true)
        setBlackLogo(true)
      }
    })
  }

  return {
    blackLogo,
    isWhite,
    mobileMenuIsOpen,
    setIsWhite,
    setBlackLogo,
    initWindowResizeWatcher,
  }
})
