<template>
  <div
    :data-has-bg-img="hasBackgroundImage"
    class="relative overflow-hidden"
    :class="colSpan"
  >
    <div class="relative flex items-center">
      <div
        v-if="$slots.iconPrefix"
        class="absolute left-6 z-10 w-6 text-blue-800"
      >
        <slot name="iconPrefix" />
      </div>

      <Field
        :id="handle || field.handle"
        :value="value"
        :name="handle || field.handle"
        :label="label || field.label || field.display"
        :type="type || field.type || field.config.input_type"
        :as="tag"
        :placeholder="handle || field.handle"
        :disabled="disabled"
        :rules="rulesAsString"
        :class="[
          { 'pl-14': $slots.iconPrefix },
          error
            ? 'border-red focus:border-transparent has-bg-img:pb-9'
            : 'border-transparent',
          { 'min-h-32': (type || field.type) === 'textarea' },
        ]"
        class="peer block w-full rounded-[0.0625rem] border bg-blue-100/20 px-6 pb-[0.8rem] pt-[1.2rem] text-md text-blue-dark-700 outline-none transition placeholder:text-transparent focus:outline-none focus:ring-2 has-bg-img:bg-white"
        rows="4"
        :autocomplete="autocomplete"
        @input="$emit('input', $event.target.value)"
        @focus="hasFocus = true"
        @blur="hasFocus = false"
      />

      <label
        :for="handle || field.handle"
        class="absolute top-1.5 w-full text-xs text-blue-dark-700/60 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-md peer-placeholder-shown:text-blue-dark-700/60 peer-focus:top-1.5 peer-focus:text-xs peer-focus:text-blue-dark-700/60"
        :class="[$slots.iconPrefix ? 'left-14' : 'left-6']"
      >
        <transition name="fade">
          <span v-if="hasFocus" class="absolute first-letter:uppercase">
            {{ label || field.label || field.display }}
            {{ !isRequired ? $t('optional') : '' }}
          </span>
          <span v-else class="absolute first-letter:uppercase">
            {{ placeholderText }} {{ !isRequired ? $t('optional') : '' }}
          </span>
        </transition>
      </label>

      <div
        v-if="$slots.iconSuffix"
        class="absolute right-6 z-10 w-6 text-blue-800"
      >
        <slot name="iconSuffix" />
      </div>
    </div>

    <transition name="fade">
      <p
        v-if="error"
        class="pt-1 text-sm text-red has-bg-img:absolute has-bg-img:bottom-2.5 has-bg-img:line-clamp-1 has-bg-img:px-5"
      >
        {{ error }}
      </p>
    </transition>
  </div>
</template>

<script>
import { Field } from 'vee-validate'

export default {
  components: {
    Field,
  },

  inject: {
    hasBackgroundImage: {
      default: false,
    },
  },

  props: {
    handle: {
      type: String,
      default: null,
    },

    field: {
      type: Object,
      default: null,
    },

    value: {
      type: null,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    rules: {
      type: [Array, String],
      default: '',
    },

    error: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    width: {
      type: [Number, String],
      default: null,
    },

    autocomplete: {
      type: String,
      default: null,
    },
  },

  emits: ['input'],

  data() {
    return {
      hasFocus: false,
    }
  },

  computed: {
    tag() {
      if ((this.type || this.field?.type) === 'textarea') {
        return 'textarea'
      }

      return 'input'
    },

    rulesAsString() {
      if (Array.isArray(this.rules)) {
        return this.rules.join('|')
      }
      return this.rules
    },

    isRequired() {
      return this.rulesAsString.includes('required')
    },

    colSpan() {
      const width = parseInt(this.width) || this.field?.width || 100

      switch (width) {
        case 25:
          return 'md:col-span-6 lg:col-span-3 col-span-12'
        case 33:
          return 'md:col-span-6 lg:col-span-4 col-span-12'
        case 50:
          return 'md:col-span-6 lg:col-span-6 col-span-12'
        case 66:
          return 'md:col-span-6 lg:col-span-8 col-span-12'
        default:
          return 'col-span-12'
      }
    },

    placeholderText() {
      return (
        this.placeholder ||
        this.field?.config?.placeholder ||
        this.label ||
        this.field?.display
      )
    },
  },
}
</script>

<style scoped>
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>
