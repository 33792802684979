<template>
  <div
    :class="[
      { 'in-live-preview': inLivePreview },
      { 'animated-container': !disabled },
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Only animate when ready
     * when FALSE the css animation classes
     * are still applied
     */
    ready: {
      type: Boolean,
      default: true,
    },

    /**
     * How many seconds
     * GSAP should wait initially
     * until it plays the animatin
     */
    initialDelay: {
      type: Number,
      default: null,
    },

    /**
     * Disable all animations
     * and make everything visible when necessary
     *
     * (like there were no animations set)
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Show GSAP markers
     */
    showMarkers: {
      type: Boolean,
      default: false,
    },

    /**
     * GSAP Scrolltrigger start
     */
    triggerStart: {
      type: String,
      default: 'top bottom',
    },

    stagger: {
      type: Number,
      default: 0.15,
    },
  },

  data() {
    return {
      tl: null,
      context: null,
      inLivePreview: false,
    }
  },

  async mounted() {
    this.checkIfIsInLivePreview()

    await this.$nextTick()

    // Wait for the page animation to finish
    await new Promise((resolve) => setTimeout(resolve, 300))

    this.initAnimation()
  },

  beforeUnmount() {
    if (this.context) {
      this.context.kill()
    }
  },

  methods: {
    initAnimation() {
      // do not animate when...
      if (this.inLivePreview || !this.$el) {
        return
      }

      // otherwise animate that shit
      this.context = this.$gsap.context(() => {
        // init gsap timeline
        this.tl = this.$gsap
          .timeline({
            scrollTrigger: {
              trigger: this.$el,
              start: this.triggerStart,
              markers: this.showMarkers,
            },
          })
          .to(
            '.fade-in',
            {
              opacity: 1,
              y: 0,
              duration: 0.7,
              stagger: this.stagger,
              ease: 'power2.out',
            },
            0
          )
          .to(
            '.slide-in-left',
            {
              opacity: 1,
              x: 0,
              duration: 0.7,
              stagger: this.stagger,
              ease: 'power2.out',
            },
            0
          )
      }, this.$el)
    },

    checkIfIsInLivePreview() {
      this.inLivePreview = !!this.$route.query['live-preview']
    },
  },
}
</script>

<style lang="scss">
.animated-container:not(.in-live-preview) {
  .fade-in {
    @apply opacity-0 translate-y-10;
  }

  .slide-in-left {
    @apply opacity-0 -translate-x-10;
  }
}
</style>
