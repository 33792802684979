<template>
  <div
    class="sticky top-navigation-desktop h-min bg-gradient-to-tr from-blue-50/60 to-blue-10/60 px-8 py-10"
  >
    <CourtDecisionMetaTiles is-white :page="page" class="space-y-4" />

    <CourtDecisionMeta class="mt-10 space-y-4" :page="page" />

    <AppButton
      v-if="page.link_reference"
      class="mt-10"
      :to="page.link_reference"
    >
      {{ $t('court_decision.open_reference') }}
    </AppButton>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
}
</script>
