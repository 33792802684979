<template>
  <div
    class="group relative flex aspect-[3/4] min-h-[20rem] flex-col justify-end overflow-hidden rounded-sm sm:min-h-[17.5rem] lg:min-h-[22rem]"
    tabindex="0"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @focus="hover = true"
    @blur="hover = false"
    @focusin="hover = true"
    @focusout="hover = false"
  >
    <!-- IMAGE DEFAULT -->
    <div
      class="absolute inset-0 bg-gradient-to-tr from-blue-dark-800 to-blue-dark-600 transition-transform duration-300 ease-in-out"
      :class="hover && data.image_2 ? '-translate-x-full' : 'translate-x-0'"
    >
      <AppImage
        v-if="data.image"
        :alt="$i18n.locale === 'fr' ? data.image.alt_fr : data.image.alt"
        :style="`object-position: ${data.image.focus_css};`"
        class="h-full w-full object-cover"
        loading="lazy"
        :src="data.image"
      />
    </div>
    <!-- IMAGE 2 FOR HOVER -->
    <div
      v-if="data.image_2"
      class="absolute inset-0 transition-transform duration-300 ease-in-out"
      :class="hover ? 'translate-x-0' : 'translate-x-full'"
    >
      <!-- no lazy loading here -->
      <AppImage
        :style="`object-position: ${data.image_2.focus_css};`"
        class="h-full w-full object-cover"
        :src="data.image_2"
      />
    </div>

    <!-- TEXT -->
    <div
      class="relative flex gap-6 bg-gradient-to-t from-[#012D43]/60 via-[#033852]/10 to-transparent px-6 pb-6 pt-18 text-white"
    >
      <div class="w-full">
        <p class="mb-1 text-xl font-medium leading-9 sm:text-2xl">
          {{ data.title }}
        </p>
        <p class="text-base font-medium">
          {{ data.job_title }}
        </p>

        <template v-if="data.phone || data.email">
          <TransitionExpand
            class="w-full overflow-hidden transition-all duration-300 ease-in-out"
            :open="hover"
          >
            <div class="pb-1 pl-[0.125rem] pt-2">
              <a
                v-if="data.phone"
                :href="`tel:${data.phone}`"
                class="flex w-fit items-center gap-2 text-base ring-offset-0 transition-colors hover:text-blue-800"
              >
                <PhoneIcon class="w-4 shrink-0" />
                {{ data.phone }}
              </a>

              <a
                v-if="data.email"
                :href="`mailto:${data.email}`"
                class="flex w-fit items-center gap-2 hyphens-auto text-base ring-offset-0 transition-colors hover:text-blue-800"
              >
                <MailOutlinedIcon class="w-4 shrink-0" />
                {{ data.email }}
              </a>
            </div>
          </TransitionExpand>
        </template>
      </div>

      <PlusIcon
        v-if="data.phone || data.email || data.image_2"
        class="h-4 w-4 shrink-0 self-end transition-transform duration-300 ease-in-out"
        :class="{
          'group-hover:rotate-90': data.phone || data.email || data.image_2,
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hover: false,
    }
  },
}
</script>
