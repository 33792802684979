<template>
  <NuxtLink
    :to="data.page.url"
    class="nav-link list-title justify-between text-lg font-medium"
  >
    {{ data.page.title }}
  </NuxtLink>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
