<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8.023 14.546"
    aria-hidden="true"
    stroke-width="1.5"
  >
    <path
      data-name="Path 444"
      d="M12.425,6.212,6.212,0,0,6.212"
      transform="translate(7.273 1.061) rotate(90)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
  </svg>
</template>
